import React, { createContext, useContext, useState, useCallback } from "react";

// Define the type for the context
interface FormSubmitContextType {
  // Function to register a submit handler
  registerSubmitHandler: (id: string, handler: () => Promise<boolean>) => void;
  // Function to trigger the current submit handler
  triggerCurrentSubmit: (id: string) => Promise<boolean>;
}

// Create the context with default values
const FormSubmitContext = createContext<FormSubmitContextType>({
  registerSubmitHandler: () => {},
  triggerCurrentSubmit: async () => true,
});

// Custom hook to access the form submit context
export const useFormSubmitContext = (): FormSubmitContextType =>
  useContext(FormSubmitContext);

// Custom hook to provide the form submit context
export const useProvideFormSubmit = (): FormSubmitContextType => {
  // State to hold the current submit handler
  const [handlers, setHandlers] = useState<{
    [key: string]: () => Promise<boolean>;
  }>({});

  // Function to register a submit handler
  const registerSubmitHandler = useCallback(
    (id: string, handler: () => Promise<boolean>) => {
      setHandlers((prevHandlers) => ({ ...prevHandlers, [id]: handler }));
    },
    []
  );

  // Function to trigger the current submit handler
  const triggerCurrentSubmit = useCallback(
    async (id: string) => {
      const handler = handlers[id];
      if (!handler) {
        console.error("No submit handler registered for form:", id);
        return false;
      }
      try {
        return await handler();
      } catch (error) {
        console.error("Error occurred during form submission:", error);
        return false;
      }
    },
    [handlers]
  );

  return { registerSubmitHandler, triggerCurrentSubmit };
};

// Component to provide the form submit context to its children
export const FormSubmitProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // Get the form submit context using the custom hook
  const formSubmit = useProvideFormSubmit();
  // Provide the form submit context to its children
  return (
    <FormSubmitContext.Provider value={formSubmit}>
      {children}
    </FormSubmitContext.Provider>
  );
};
