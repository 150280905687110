export const TrashCan = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.75 2.25V3H15.5V4.5H14.75V14.25C14.75 15.075 14.075 15.75 13.25 15.75H5.75C4.925 15.75 4.25 15.075 4.25 14.25V4.5H3.5V3H7.25V2.25H11.75ZM5.75 14.25H13.25V4.5H5.75V14.25ZM7.25 6H8.75V12.75H7.25V6ZM11.75 6H10.25V12.75H11.75V6Z"
      fill="#BA1A1A"
    />
  </svg>
);
