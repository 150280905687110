import React from "react";

const Avatar = ({ name }: { name: string }) => {
  const getInitials = (name: string) => {
    if (!name) return "";
    const parts = name.split(" ");
    const firstLetter = parts[0] && parts[0][0];
    const secondLetter = parts[1] && parts[1][0];
    return `${firstLetter || ""}${secondLetter || ""}`.toUpperCase();
  };

  return (
    <div className="avatar placeholder">
      <div className="text-smooth-base bg-smooth-secondary-90/80 rounded-full w-10 h-10 flex items-center justify-center">
        <span>{getInitials(name)}</span>
      </div>
    </div>
  );
};

export default Avatar;
