import {
  observable,
  action,
  computed,
  makeObservable,
  runInAction,
} from "mobx";

class BaseStore<T = any> {
  loading = false;
  fetchingEntity = false;
  error: string | null = null;
  entity: T | null = null;

  constructor() {
    makeObservable(this, {
      loading: observable,
      fetchingEntity: observable,
      error: observable,
      entity: observable,
      updateEntity: action,
      setLoading: action,
      setError: action,
      setEntity: action,
      mergeDeep: action,
      handleError: action,
      getEntity: computed,
      isLoading: computed,
      getError: computed,
    });
  }

  /**
   * Sets the loading state.
   * @param {boolean} loading - The loading state to set.
   */
  setLoading(loading: boolean): void {
    this.loading = loading;
  }

  /**
   * Sets the loading state.
   * @param {boolean} loading - The loading state to set.
   */
  setFetchingEntity(fetching: boolean): void {
    this.fetchingEntity = fetching;
  }

  /**
   * Sets the error message.
   * @param {string | null} errorMessage - The error message to set.
   */
  setError(errorMessage: string | null): void {
    this.error = errorMessage;
  }

  /**
   * Sets the main entity object.
   * @param {T | null} entity - The entity to set.
   */
  setEntity(entity: T | null): void {
    this.entity = entity;
  }

  handleError(error: any): void {
    const message =
      error instanceof Error ? error.message : "An unexpected error occurred";
    this.setError(message);
  }

  mergeDeep(target: any, source: any) {
    Object.keys(source).forEach((key) => {
      if (source[key] && typeof source[key] === "object") {
        if (!target[key]) target[key] = Array.isArray(source[key]) ? [] : {};
        this.mergeDeep(target[key], source[key]);
      } else {
        runInAction(() => {
          target[key] = source[key];
        });
      }
    });
  }

  updateEntity(updates: Partial<T>) {
    if (this.entity) {
      this.mergeDeep(this.entity, updates);
    }

    return this.entity;
  }

  /**
   * Returns the current entity
   */
  get getEntity(): T | null {
    return this.entity;
  }

  /**
   * Returns whether the store is currently in a loading state.
   */
  get isLoading(): boolean {
    return this.loading;
  }

  /**
   * Returns the error.
   */
  get getError(): string | null {
    return this.error;
  }
}

export default BaseStore;
