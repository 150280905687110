import React, { ReactElement, useEffect } from "react";
import { TextVariant } from "../../utils/const";
import Text from "../Text";
import { observer } from "mobx-react";
import { useStores } from "../../context";
import { Condition } from "../../models/weather";
import { Skeleton } from "@mui/material";

const WeatherVitals = observer(() => {
  const { weatherStore } = useStores();
  const weather = weatherStore.getEntity;

  useEffect(() => {
    // fetch the weather when component firs mounts
    weatherStore.fetchWeather();
  }, [weatherStore]);

  if (!weather || weatherStore.isLoading) {
    return <Skeleton variant="rounded" height={25} width={130} />;
  }

  const renderCondition = (condition: Condition) => {
    let content: ReactElement;
    switch (condition.code) {
      case 1000: // sunny
        content = (
          <span className="material-symbols-rounded text-smooth-warning inline-block">
            sunny
          </span>
        );
        break;
      case 1003: // partly cloudy
        content = (
          <span className="material-symbols-rounded text-smooth-neutral-60 inline-block">
            partly_cloudy_day
          </span>
        );
        break;
      case 1006: // cloudy
        content = (
          <span className="material-symbols-rounded text-smooth-neutral-60 inline-block">
            cloudy
          </span>
        );
        break;
      case 1009 || 1135: // overcast / foggy
        content = (
          <span className="material-symbols-rounded text-smooth-neutral-60 inline-block">
            foggy
          </span>
        );
        break;
      case 1063 || 1150 || 1153 || 1180 || 1183 || 1186 || 1189 || 1192 || 1195: // rain
        content = (
          <span className="material-symbols-rounded text-smooth-tertiary-60 inline-block">
            rainy
          </span>
        );
        break;
      case 1087 || 1273 || 1276 || 1279 || 1282: // thunder / storm
        content = (
          <span className="material-symbols-rounded text-smooth-neutral-60 inline-block">
            thunderstorm
          </span>
        );
        break;
      default:
        content = (
          <span className="material-symbols-rounded text-smooth-warning inline-block">
            sunny
          </span>
        );
        break;
    }

    return content;
  };

  const current = weather.current;
  return (
    <div className="grid grid-flow-col auto-cols-max gap-3">
      {current.temp_f ? (
        <div className="flex flex-row items-center">
          {renderCondition(current.condition)}
          <Text variant={TextVariant.LabelLarge} className="ml-1 font-medium">
            {Math.round(current.temp_f)}&deg;
          </Text>
        </div>
      ) : null}
      {current.precip_in ? (
        <div className="flex flex-row items-center">
          <span className="material-symbols-rounded text-smooth-tertiary-80 !text-xl">
            humidity_mid
          </span>
          <Text variant={TextVariant.LabelLarge} className="ml-1 font-medium">
            {Math.round(current.precip_in)}&#x22;
          </Text>
        </div>
      ) : null}
      {current.wind_mph ? (
        <div className="flex flex-row items-center">
          <span className="material-symbols-rounded text-smooth-tertiary-70">
            air
          </span>
          <Text variant={TextVariant.LabelLarge} className="ml-1 font-medium">
            {Math.round(current.wind_mph)}mph
          </Text>
        </div>
      ) : null}
    </div>
  );
});

export default WeatherVitals;
