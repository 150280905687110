import React from "react";
import classNames from "classnames";

interface FloatingActionButtonProps {
  onClickHandler?: () => void;
  className?: string;
}
const FloatingActionButton = ({
  onClickHandler,
  className,
}: FloatingActionButtonProps) => {
  const btnClasses = classNames(
    "btn btn-primary w-14 h-14 bottom-5 right-5 fixed shadow-elevation-light",
    className
  );
  return (
    <button className={btnClasses} onClick={onClickHandler}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 4.5v15m7.5-7.5h-15"
        />
      </svg>
    </button>
  );
};

export default FloatingActionButton;
