import React from "react";
import Text from "../Text";
import { TextVariant } from "../../utils";

const Timer = ({
  currentTime,
  totalTime,
}: {
  currentTime: number;
  totalTime: number;
}) => {
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.ceil(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  return (
    <Text variant={TextVariant.BodySmall}>
      {formatTime(currentTime)} of {formatTime(totalTime)}
    </Text>
  );
};

export default Timer;
