import React, { FC, ReactNode } from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import {
  useFormContext,
  Controller,
  UseControllerProps,
} from "react-hook-form";

export interface TextInputProps extends UseControllerProps {
  id?: string;
  name: string;
  label?: string;
  placeholder?: string;
  type?: string;
  autoComplete?: string;
  helperText?: string;
  hidden?: boolean;
  endAdornment?: ReactNode;
}

const TextInput: FC<TextInputProps> = ({
  name,
  label,
  placeholder,
  rules,
  autoComplete,
  id,
  helperText,
  hidden,
  type = "text",
  defaultValue,
  endAdornment,
  ...controllerProps
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      {...controllerProps}
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => {
        const formHelperText = error ? error.message : helperText;
        return (
          <FormControl>
            <InputLabel htmlFor={id}>{label}</InputLabel>
            <OutlinedInput
              {...field}
              type={type}
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#C3C8BB",
                  borderRadius: "6px",
                },
                ".MuiInputBase-input.MuiOutlinedInput-input": {
                  height: "auto",
                },
              }}
              id={id}
              error={!!error}
              fullWidth
              label={label}
              autoComplete="off"
              hidden={hidden}
              placeholder={placeholder}
              endAdornment={endAdornment}
            />
            {formHelperText && (
              <FormHelperText
                className={error ? "!text-smooth-error" : "!text-smooth-base"}
              >
                {formHelperText}
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
};

export default TextInput;
