import React from "react";
import Button, { ButtonProps } from "./Button";
import classNames from "classnames";

const SecondaryButton = ({
  children,
  onClickHandler,
  className,
}: ButtonProps) => {
  const buttonClasses = classNames(
    "btn-ghost border-smooth-secondary-90 bg-smooth-secondary-90 text-smooth-secondary-10 px-6 py-2.5 normal-case font-medium w-full active:bg-smooth-secondary-95 hover:bg-smooth-secondary-95",
    className
  );
  return (
    <Button className={buttonClasses} onClickHandler={onClickHandler}>
      {children}
    </Button>
  );
};

export default SecondaryButton;
