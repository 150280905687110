import React, { SVGProps } from "react";

export const Battery = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="20"
    viewBox="0 0 10 20"
    fill="none"
  >
    <path
      d="M1.11801 19.5162C0.853895 19.5162 0.632509 19.4269 0.453846 19.2482C0.275183 19.0695 0.185852 18.8481 0.185852 18.584V3.66958C0.185852 3.40547 0.275183 3.18409 0.453846 3.00542C0.632509 2.82676 0.853895 2.73743 1.11801 2.73743H2.98231V0.873123H6.71093V2.73743H8.57523C8.83934 2.73743 9.06073 2.82676 9.23939 3.00542C9.41805 3.18409 9.50739 3.40547 9.50739 3.66958V18.584C9.50739 18.8481 9.41805 19.0695 9.23939 19.2482C9.06073 19.4269 8.83934 19.5162 8.57523 19.5162H1.11801ZM2.05016 6.46604H7.64308V4.60174H2.05016V6.46604Z"
      fill="#ABACA5"
    />
  </svg>
);
