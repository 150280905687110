// Deps
import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// Styles
import "./App.css";
import "mapbox-gl/dist/mapbox-gl.css";

// Context/Providers
import { BottomSheetProvider, SnackbarProvider } from "./context";
import { StoreContext } from "./context/StoreContext";
import { RootStore } from "./context/stores";

// Components
import MainRouter from "./router";
import { Loading, AppBar, BottomSheet, ErrorListener } from "./components";
import { ThemeProvider, createTheme } from "@mui/material";
import WebFont from "webfontloader";

const App = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  // custom theme for MUI components
  const theme = createTheme({
    palette: {
      primary: {
        light: "#5EA139",
        main: "#458621",
        dark: "#2C6C03",
      },
      secondary: {
        light: "#87957C",
        main: "#6E7B63",
        dark: "#55624C",
      },
      error: {
        light: "#FF5449",
        main: "#DE3730",
        dark: "#BA1A1A",
      },
      grey: {
        400: "#C3C8BB",
        500: "#E0E4D6",
        600: "#EEF3E4",
      },
    },
  });

  useEffect(() => {
    WebFont.load({
      google: {
        families: [
          "Material Symbols Rounded:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200",
          "Roboto:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500",
        ],
      },
      loading: () => {
        // This function is called when the fonts start loading
        document.body.classList.add("font-loading");
        document.body.classList.remove("font-loaded");
      },
      active: () => {
        // This function is called when all fonts have been loaded
        // Remove the class or style that hides your text or shows a loader
        document.body.classList.remove("font-loading");
        document.body.classList.add("font-loaded");
      },
      inactive: () => {
        // This function is called when the fonts cannot be loaded
        document.body.classList.remove("font-loading");
        document.body.classList.add("font-loaded");
      },
    });
  }, []);

  // they say it takes a village to raise a baby
  // and in this case the village is context providers,
  // and the baby is the app
  return (
    <ThemeProvider theme={theme}>
      <StoreContext.Provider value={new RootStore()}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <BottomSheetProvider>
            <SnackbarProvider>
              <Router>
                <Suspense fallback={<Loading />}>
                  <AppBar onMenuClick={toggleDrawer} />
                  <MainRouter />
                </Suspense>
              </Router>
              <BottomSheet />
              <ErrorListener />
            </SnackbarProvider>
          </BottomSheetProvider>
        </LocalizationProvider>
      </StoreContext.Provider>
    </ThemeProvider>
  );
};

export default App;
