export const ArrowRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path d="M10 17L15 12L10 7V17Z" fill="#43483E" />
  </svg>
);
