import React from "react";
import Text from "../Text";
import { TextVariant } from "../../utils/const";

const Badge = ({ label }: { label: string }) => (
  <span className="border border-smooth-primary py-1.5 px-4 rounded-lg block">
    <Text
      variant={TextVariant.LabelLarge}
      className="text-smooth-primary font-medium"
    >
      {label}
    </Text>
  </span>
);
export default Badge;
