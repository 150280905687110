import React, {
  isValidElement,
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
  ReactElement,
  useMemo,
} from "react";

const BottomSheetContext = createContext({
  isBottomSheetVisible: false,
  bottomSheetContent: null as ReactElement | null,
  showBottomSheet: (content: ReactElement) => {},
  hideBottomSheet: () => {},
  isSideSheetVisible: false,
  sideSheetContent: null as ReactElement | null,
  showSideSheet: (content: ReactElement) => {},
  hideSideSheet: () => {},
});

export const useBottomSheet = () => useContext(BottomSheetContext);

export const BottomSheetProvider = ({ children }: { children: ReactNode }) => {
  const [isBottomSheetVisible, setIsBottomSheetVisible] = useState(false);
  const [bottomSheetContent, setBottomSheetContent] =
    useState<ReactElement | null>(null);
  const [isSideSheetVisible, setIsSideSheetVisible] = useState(false);
  const [sideSheetContent, setSideSheetContent] = useState<ReactElement | null>(
    null
  );

  const showBottomSheet = useCallback((content: ReactElement) => {
    if (isValidElement(content)) {
      setBottomSheetContent(content);
      setIsBottomSheetVisible(true);
    } else {
      console.error(
        "The content provided to showBottomSheet is not a valid React element."
      );
    }
  }, []);

  const hideBottomSheet = useCallback(() => {
    setIsBottomSheetVisible(false);
    setTimeout(() => {
      setBottomSheetContent(null);
    }, 300);
  }, []);

  const showSideSheet = useCallback((content: ReactElement) => {
    if (isValidElement(content)) {
      setSideSheetContent(content);
      setIsSideSheetVisible(true);
    } else {
      console.error(
        "The content provided to showSideSheet is not a valid React element."
      );
    }
  }, []);

  const hideSideSheet = useCallback(() => {
    setIsSideSheetVisible(false);
    setTimeout(() => {
      setSideSheetContent(null);
    }, 100);
  }, []);

  const contextValue = useMemo(
    () => ({
      isBottomSheetVisible,
      bottomSheetContent,
      showBottomSheet,
      hideBottomSheet,
      isSideSheetVisible,
      sideSheetContent,
      showSideSheet,
      hideSideSheet,
    }),
    [
      isBottomSheetVisible,
      bottomSheetContent,
      showBottomSheet,
      hideBottomSheet,
      isSideSheetVisible,
      sideSheetContent,
      showSideSheet,
      hideSideSheet,
    ]
  );

  return (
    <BottomSheetContext.Provider value={contextValue}>
      {children}
    </BottomSheetContext.Provider>
  );
};
