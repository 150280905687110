import {
  action,
  computed,
  flow,
  makeObservable,
  observable,
  reaction,
} from "mobx";
import { IRover, RoverVitals } from "../../models/rover";
import BaseStore from "./BaseStore";
import RanchStore from "./RanchStore";
import { RoverResponse, WaveResponse } from "../../api/rover";
import api from "../../api";
import { ioClient } from "../../socket";

/**
 * RoverStore manages the state and operations related to rovers in the application.
 * It handles the retrieval, updating, and tracking of rover information.
 */
class RoverStore extends BaseStore<IRover> {
  ranchStore: RanchStore;
  rovers = new Map<string, IRover>();

  constructor(ranchStore: RanchStore) {
    super();
    this.ranchStore = ranchStore;
    makeObservable(this, {
      rovers: observable,
      fetchRoversByRanch: flow,
      createRover: flow,
      updateRover: flow,
      deleteRover: flow,
      disableRover: flow,
      enableRover: flow,
      fetchRoverByID: flow,
      connectPhysicalRover: flow,
      waveToRover: flow,
      toggleEngine: flow,
      toggleFeed: flow,
      addRover: action,
      removeRover: action,
      getRoverByID: action,
      updateRoverVitals: action,
      setRovers: action,
      getRovers: computed,
      reset: action,
      setupSocketListeners: action,
    });

    // do this to make sure we always get the rovers for the ranch
    reaction(
      () => this.ranchStore.getEntity,
      (ranch) => {
        if (ranch && ranch.ranch_id) {
          this.fetchRoversByRanch(ranch.ranch_id);
        } else {
          this.reset();
        }
      }
    );

    this.setupSocketListeners();
  }

  reset(): void {
    this.setLoading(false);
    this.setError(null);
    this.setEntity(null);
  }

  *fetchRoversByRanch(ranchId: string) {
    this.setFetchingEntity(true);
    this.setError(null);
    try {
      if (ranchId) {
        const { data }: RoverResponse = yield api.getRoversByRanch(ranchId);
        if (data && data.rovers) {
          this.setRovers(data.rovers);
          // set default entity to first rover
          this.setEntity(data.rovers[0]);
        }
      }
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setFetchingEntity(false);
    }
  }

  setupSocketListeners = () => {
    ioClient.onEvent<{ roverID: string; vitals: Partial<RoverVitals> }>(
      "ROVER_VITALS",
      ({ roverID, vitals }) => {
        this.updateRoverVitals(roverID, { ...vitals });
      }
    );

    ioClient.onEvent<{ roverID: string; associated: boolean }>(
      "ROVER_ASSOCIATED",
      ({ roverID, associated }) => {
        const rover = this.getRoverByID(roverID);
        if (rover) {
          if (!this.getEntity) {
            this.setEntity(rover);
          }
          this.updateEntity({ associated });
          if (this.getEntity) this.addRover(this.getEntity);
        }
      }
    );
  };

  updateRoverVitals(roverID: string, newVitals: Partial<RoverVitals>) {
    const rover = this.getRoverByID(roverID);
    if (rover) {
      if (!this.getEntity) {
        this.setEntity(rover);
      }

      if (newVitals.coordinates) {
        rover.data.coordinates = newVitals.coordinates;
      }

      // Only update the properties that have changed
      this.updateEntity({
        data: { ...newVitals },
      });
      if (this.getEntity) this.addRover(this.getEntity);
    }
  }

  *fetchRoverByID(roverID: string) {
    this.setFetchingEntity(true);
    this.setError(null);

    try {
      const { data }: RoverResponse = yield api.getRoverByID(roverID);
      if (data && data.rover) {
        this.setEntity(data.rover);
        this.addRover(data.rover);
      }
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setFetchingEntity(false);
    }
  }

  *connectPhysicalRover(roverID: string, userID: string) {
    this.setLoading(true);
    this.setError(null);

    try {
      const { data }: RoverResponse = yield api.connectPhysicalRover(
        roverID,
        userID
      );

      if (data && data.rover) {
        // fetch and set the rover in state
        // this.fetchRoverByID(data.rover.rover_id);
        this.updateEntity({ ...data.rover });
        this.addRover(data.rover);
      }
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  *waveToRover(roverID: string) {
    this.setLoading(true);
    this.setError(null);
    try {
      const { data }: WaveResponse = yield api.waveToRover(roverID);
      this.updateEntity({ data: { online: data.wave } });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  *toggleEngine(roverID: string, engine: boolean) {
    this.setError(null);
    try {
      // TODO: Add error checking to throw error if not okay resp
      yield api.toggleEngine(roverID, {
        engine,
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  *toggleFeed(roverID: string, feed: boolean) {
    this.setError(null);
    try {
      yield api.toggleFeed(roverID, {
        feed,
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  *createRover(newRover: Partial<IRover>) {
    this.setLoading(true);
    this.setError(null);

    try {
      const { data }: RoverResponse = yield api.createRover(newRover);
      if (data && data.rover) {
        // fetch and set the rover in state
        // this.fetchRoverByID(data.rover.rover_id);
        this.addRover(data.rover);
      }
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  *updateRover(roverID: string, updatedRover: Partial<IRover>) {
    this.setLoading(true);
    this.setError(null);

    try {
      const { data }: RoverResponse = yield api.updateRover(
        roverID,
        updatedRover
      );
      if (data && data.rover) {
        this.fetchRoverByID(data.rover.rover_id);
      }
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  *deleteRover(roverID: string) {
    this.setLoading(true);
    this.setError(null);

    try {
      yield api.deleteRover(roverID);
      this.removeRover(roverID);
    } catch (error) {
      this.handleError(error);
    } finally {
      this.reset();
    }
  }

  *enableRover(roverID: string) {
    this.setLoading(true);
    this.setError(null);

    try {
      this.updateRoverVitals(roverID, { operationsEnabled: true });
      yield api.enableRover(roverID);
      this.fetchRoverByID(roverID);
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
      const rover = this.getRoverByID(roverID);
      this.setEntity(rover);
    }
  }

  *disableRover(roverID: string) {
    this.setLoading(true);
    this.setError(null);

    try {
      this.updateRoverVitals(roverID, { operationsEnabled: false });
      yield api.disableRover(roverID);
      this.fetchRoverByID(roverID);
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
      const rover = this.getRoverByID(roverID);
      this.setEntity(rover);
    }
  }

  addRover(rover: IRover) {
    this.rovers.set(rover.rover_id, rover);
  }

  removeRover(roverID: string) {
    this.rovers.delete(roverID);
  }

  getRoverByID(roverID: string): IRover | null {
    return this.rovers.get(roverID) || null;
  }

  setRovers(rovers: IRover[]) {
    this.rovers.clear();
    rovers.forEach((rover) => {
      this.addRover(rover);
    });
  }

  get getRovers(): IRover[] {
    return Array.from(this.rovers.values()) || [];
  }
}

export default RoverStore;
