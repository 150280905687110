import React from "react";
import { JSX } from "react/jsx-runtime";

export const PrimaryLogoFull = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={60}
    viewBox="0 0 562.5 221.25"
    {...props}
  >
    <path
      fill="#458621"
      d="M251.66 128.228c-4.188 0-6.281-2.094-6.281-6.282v-10.984h5.578v10.125c0 .992.156 1.633.468 1.922.313.293.961.437 1.954.437h3.53c1 0 1.657-.144 1.97-.437.312-.29.468-.93.468-1.922v-17.969c0-1.05-.148-1.719-.437-2-.281-.289-.95-.437-2-.437h-5.406c-4.188 0-6.282-2.094-6.282-6.282V74.306c0-4.188 2.094-6.282 6.282-6.282h7.062c4.188 0 6.281 2.094 6.281 6.282v13.031h-5.578V75.243c0-1.039-.148-1.703-.437-1.984-.282-.29-.95-.438-2-.438h-3.61c-.992 0-1.64.149-1.953.438-.312.281-.469.945-.469 1.984V93.54c0 .992.157 1.649.47 1.969.312.312.96.469 1.952.469h5.422c4.188 0 6.281 2.09 6.281 6.265v19.703c0 4.188-2.093 6.282-6.28 6.282ZM293.166 110.65l8.484-42.626h6.125v60.204h-4.797V82.946l-6.828 34.688h-5.797l-6.828-35v45.594h-4.797V68.024h6.047ZM336.724 68.024c4.239 0 6.36 2.094 6.36 6.282v47.64c0 4.188-2.121 6.282-6.36 6.282h-8.078c-4.187 0-6.281-2.094-6.281-6.282v-47.64c0-4.188 2.094-6.282 6.281-6.282Zm.797 7.22c0-.99-.148-1.641-.437-1.954-.293-.312-.934-.469-1.922-.469h-4.875c-.992 0-1.64.149-1.953.438-.313.281-.469.945-.469 1.984v45.844c0 .992.156 1.633.469 1.922.312.293.96.437 1.953.437h4.875c.988 0 1.629-.144 1.922-.437.289-.29.437-.93.437-1.922ZM371.648 68.024c4.239 0 6.36 2.094 6.36 6.282v47.64c0 4.188-2.121 6.282-6.36 6.282h-8.078c-4.187 0-6.281-2.094-6.281-6.282v-47.64c0-4.188 2.094-6.282 6.281-6.282Zm.797 7.22c0-.99-.148-1.641-.437-1.954-.293-.312-.934-.469-1.922-.469h-4.875c-.992 0-1.64.149-1.953.438-.313.281-.47.945-.47 1.984v45.844c0 .992.157 1.633.47 1.922.312.293.96.437 1.953.437h4.875c.988 0 1.629-.144 1.922-.437.289-.29.437-.93.437-1.922ZM410.025 68.024v4.797h-7.922v55.407h-5.5V72.82h-7.921v-4.797ZM437.186 95.728V68.024h5.578v60.204h-5.578v-27.782H426.67v27.782h-5.578V68.024h5.578v27.704Zm0 0"
    />
    <path
      fill="#1e5100"
      d="M496.293 128.228h-5.579l-1.718-12.329h-10.532l-1.562 12.329h-5.578l9.5-60.204h5.031Zm-12.875-51.094-4.313 34.062h9.172ZM528.07 88.04h-5.656V75.165c0-.988-.149-1.64-.438-1.953-.28-.313-.918-.469-1.906-.469h-4.328c-.992 0-1.64.156-1.953.469-.313.312-.469.965-.469 1.953v46c0 .992.156 1.649.469 1.969.312.312.96.469 1.953.469h4.328c.988 0 1.625-.157 1.906-.47.29-.32.438-.976.438-1.968v-18.36h-3.922v-4.718h9.578v23.937c0 4.188-2.12 6.282-6.36 6.282h-7.687c-4.187 0-6.281-2.094-6.281-6.282V74.306c0-4.188 2.094-6.282 6.281-6.282h7.688c4.238 0 6.36 2.094 6.36 6.282Zm0 0"
    />
    <path
      fill="none"
      stroke="#1e5100"
      strokeWidth={0.91213}
      d="M227.836 165.414h318.016M227.836 31.68h318.016"
    />
    <path
      fill="#458621"
      d="M46.35 166.128c2.246 0 4.266 0 6.289-.223 3.816-.676 7.633-2.023 11-3.593 2.918-1.575 6.512-3.145 8.758-5.391 1.797-1.797 2.918-4.04 3.816-6.285 4.043-10.778 4.043-25.598-.449-36.153-2.918-6.96-13.695-22.004-18.86-27.617-2.695-2.695-5.163-5.836-7.636-8.758l-4.04-4.714c-.675-.899-4.042-3.59-4.042-4.715-.899-1.121-2.02-2.469-2.695-3.817-1.797-3.367-1.797-7.41-1.122-11.004.45-2.02 1.348-4.265 2.918-5.613.899-.672 2.247-1.121 3.368-1.121 1.796 0 3.367.672 4.492 2.02 1.797 2.246 1.57 5.39 1.12 8.308-.222 1.797-.671 3.817.45 5.39 1.125 1.122 2.922 1.571 4.715 1.571 2.695.223 5.39.223 8.309.223 2.695 0 5.39 0 8.085-.223 2.47-.226 8.532-.45 9.204-3.816.898-3.594-.899-6.961-1.797-10.555-2.02-7.633-4.938-18.637-11.899-23.352-4.941-4.492-16.84-6.062-23.129-6.062-26.046 0-35.25 15.492-35.699 39.52 0 4.039.223 8.308 1.348 12.124 2.691 8.31 10.101 14.145 15.941 20.657 6.512 6.96 11.676 15.27 16.614 23.578 2.472 4.265 7.636 12.125 4.94 17.289-.898 1.57-2.245 2.246-3.593 2.246-2.918 0-6.285-2.922-6.512-6.063-.222-2.695.45-5.167.676-7.859 0-3.594-.898-4.266-4.265-4.941a97.922 97.922 0 0 0-11.454-.672c-4.265 0-8.757.222-12.796.898-2.696.45-3.817 1.344-3.594 4.266.223 4.488 1.797 8.754 1.797 13.246.672 14.145 7.633 26.27 21.781 30.09 2.242.45 4.715.672 6.957.672 3.594.226 7.188.449 10.555.449Zm0 0"
    />
    <path
      fill="#1e5100"
      d="M179.958 209.237c5.164.676 10.555 1.125 15.719 1.125 5.613 0 11-.449 16.39-1.797 5.84-1.347 3.368-8.98 1.122-12.574-11.45-17.512-29.188-63.543-31.211-77.465-1.121-6.96-1.121-13.023-.446-20.207 0-1.574.672-2.922 0-4.492-.449-1.348-1.574-1.797-2.246-2.695-5.164-5.613-11.453-19.309-14.148-27.39-3.367-12.352-6.063-27.173.449-38.848.45-.45.676-.899.45-1.57 0-.677-1.122-1.126-2.02-1.348-8.309-1.797-17.067-2.247-25.598-2.247-8.309 0-21.781.227-29.414 2.696-.898.449-2.02.898-2.246 1.797-.223.672 0 1.347.226 2.02 6.063 17.288-2.472 38.843-9.656 54.562-1.57 3.367-3.367 6.738-5.836 9.656-1.574 1.57-5.617 4.039-4.941 6.062 2.695 7.41 2.695 15.493 1.348 22.903-2.696 16.617-9.657 32.781-19.536 46.48-.675.895-1.347 2.242-.449 2.918.223.223.672.223 1.121.223 1.125.226 2.246.226 3.371.226 10.551 0 20.88 0 31.211-.226 1.793 0 3.817-.223 5.614-1.121 1.793-.899 2.691-2.918 2.242-4.715-.672-2.473-.899-4.715-.45-7.188.45-4.265 2.473-8.304 6.29-10.101 4.265-1.574 8.53-2.695 13.023-3.145 1.348-.226 2.469-.226 3.816-.226 4.942 0 9.657.898 14.372 1.797 21.554 4.941 24.921 48.726 24.699 54.789 0 1.347-.899 6.96 0 8.308.449.899 1.797 1.121 2.695 1.121Zm-54.336-95.652c-.449-.898-.449-2.02-.449-3.14.672-12.352 6.734-30.313 11-41.766a179.144 179.144 0 0 1 9.879 32.332c.898 4.27 4.492 11.902-1.121 13.922-2.469.898-5.164 1.125-7.86 1.125-2.468 0-5.164-.227-7.859-.676-1.121 0-2.02-.223-2.691-.899-.45-.222-.676-.449-.899-.898Zm0 0"
    />
  </svg>
);
