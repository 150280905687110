import { action, flow, makeObservable, reaction } from "mobx";
import BaseStore from "./BaseStore";
import { IWeather } from "../../models/weather";
import api from "../../api";
import { WeatherResponse } from "../../api/weather";
import RanchStore from "./RanchStore";
import { IRanch } from "../../models/ranch";

/**
 * WeatherStore manages the state and operations related to weather information in the application.
 * It handles the retrieval, updating, and tracking of weather data.
 */
class WeatherStore extends BaseStore<IWeather> {
  ranchStore: RanchStore;

  constructor(ranchStore: RanchStore) {
    super();
    this.ranchStore = ranchStore;
    makeObservable(this, {
      fetchWeather: flow,
      reset: action,
    });

    // Add reaction to fetch weather when ranch entity changes
    reaction(
      () => this.ranchStore.getEntity,
      (ranch) => {
        if (ranch) {
          this.fetchWeather();
        } else {
          this.reset();
        }
      }
    );
  }

  reset(): void {
    this.setLoading(false);
    this.setError(null);
    this.setEntity(null);
  }

  /**
   * Fetches weather data based on a specific location and updates the store.
   */
  *fetchWeather() {
    this.setLoading(true);
    this.setFetchingEntity(true);
    try {
      const ranch = this.ranchStore.getEntity;
      if (ranch) {
        const { address } = ranch;
        const { data }: WeatherResponse = yield api.getWeatherByLocation({
          lon: address.lng,
          lat: address.lat,
        });

        if (data) {
          this.setEntity(data);
        }
      }
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
      this.setFetchingEntity(false);
    }
  }
}

export default WeatherStore;
