import { Entity } from "../models/common";
import { IEquipment } from "../models/rover";
import { Axios } from "./core";

const BASE_PATH = "/equipment";

export type EquipmentResponse = {
  data: { equipment?: IEquipment | IEquipment[]; message?: string };
};

export const createEquipment = async (
  payload: Partial<IEquipment>
): Promise<EquipmentResponse> => await Axios.post(BASE_PATH, payload);

export const updateEquipment = async (
  equipmentID: string,
  payload: Partial<IEquipment>
): Promise<EquipmentResponse> =>
  await Axios.patch(`${BASE_PATH}/${equipmentID}`, payload);

export const deleteEquipment = async (equipmentID: string): Promise<void> =>
  await Axios.delete(`${BASE_PATH}/${equipmentID}`);

export const associateEquipmentWithUser = async (payload: {
  entity: Entity;
  userID: string;
}): Promise<EquipmentResponse> =>
  await Axios.post(`${BASE_PATH}/associate-with-user`, payload);

export const checkEquipmentConnection = async (
  payload: Entity
): Promise<EquipmentResponse> => await Axios.post(`${BASE_PATH}/wave`, payload);

export const getEquipmentByEntity = async (
  entity: Entity
): Promise<EquipmentResponse> =>
  await Axios.get(
    `${BASE_PATH}/entity/${entity.entity_type}/${entity.entity_id}`
  );

export const getEquipmentByRanch = async (
  ranchID: string
): Promise<EquipmentResponse> =>
  await Axios.get(`${BASE_PATH}/ranch/${ranchID}`);
