import { RouteWithFeedLocations } from "../models/rover";
import { Axios } from "./core";

const BASE_PATH = "/routes";

export type RoutesResponse = {
  data: {
    routes?: RouteWithFeedLocations[];
    route?: RouteWithFeedLocations;
    message?: string;
  };
};

export const updateRoverRoute = async (
  roverID: string,
  routeID: string,
  payload: Partial<RouteWithFeedLocations>
): Promise<RoutesResponse> =>
  await Axios.post(`${BASE_PATH}/${roverID}/${routeID}`, payload);
