import { IPasture } from "../models/pasture";
import { Axios } from "./core";

const BASE_PATH = "/pastures";

export type PastureResponse = {
  data: { pasture?: IPasture; pastures?: IPasture[]; message?: string };
};

export const createPasture = async (
  payload: Partial<IPasture>
): Promise<PastureResponse> => await Axios.post(BASE_PATH, payload);

export const updatePasture = async (
  pasture_id: string,
  payload: Partial<IPasture>
): Promise<PastureResponse> =>
  await Axios.patch(`${BASE_PATH}/${pasture_id}`, payload);

export const deletePasture = async (pasture_id: string): Promise<void> =>
  await Axios.delete(`${BASE_PATH}/${pasture_id}`);

export const getPastureByID = async (
  pasture_id: string
): Promise<PastureResponse> => await Axios.get(`${BASE_PATH}/${pasture_id}`);

export const getPasturesByRanch = async (
  ranch_id: string
): Promise<{ pastures?: IPasture[] }> =>
  await Axios.get(`ranch/${ranch_id}/${BASE_PATH}}`);
