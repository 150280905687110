import { observable, action, flow } from "mobx";
import { ICattle } from "../../models/cattle";
import api from "../../api";

/**
 * CattleStore manages the state and operations related to cattle in the application.
 * It handles the retrieval, updating, and tracking of cattle information.
 */
class CattleStore {
  @observable cattle: ICattle[] = [];
  @observable isLoading = false;
  @observable error: string | null = null;

  /**
   * Sets the cattle data.
   * @param {ICattle[]} cattle - Array of cattle data to set.
   */
  @action
  private setCattle(cattle: ICattle[]): void {
    this.cattle = cattle;
  }

  /**
   * Sets the loading state.
   * @param {boolean} isLoading - The loading state to set.
   */
  @action
  private setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  /**
   * Sets the error message.
   * @param {string | null} error - The error message to set.
   */
  @action
  private setError(error: string | null): void {
    this.error = error;
  }

  /**
   * Handles errors by setting the appropriate error message.
   * @param {any} error - The error to handle.
   */
  @action
  private handleError(error: any): void {
    const message =
      error instanceof Error ? error.message : "An unexpected error occurred";
    this.setError(message);
  }

  /**
   * Resets the store to its initial state.
   */
  @action
  reset(): void {
    this.setCattle([]);
    this.setIsLoading(false);
    this.setError(null);
  }

  /**
   * Fetches cattle data from the API and updates the store.
   */
  @flow
  *fetchCattle() {
    this.setIsLoading(true);
    try {
      // const cattle = yield api.getCattle();
      // this.setCattle(cattle);
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setIsLoading(false);
    }
  }
}

export default CattleStore;
