import React from "react";
import { JSX } from "react/jsx-runtime";

export const RanchSyncFull = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={100}
    viewBox="0 0 562.5 221.25"
    {...props}
  >
    <path
      fill="#458621"
      d="m231.965 136.25-7.094-20.188h-4.516v20.188h-5.468V76.469h14.265c4.207 0 6.313 2.078 6.313 6.234v27.125c0 3.637-1.637 5.687-4.907 6.156l7.407 20.266Zm-11.61-55.031v30.093h7.235c.988 0 1.625-.14 1.906-.422.289-.289.437-.93.437-1.921V83.64c0-.988-.148-1.64-.437-1.953-.281-.312-.918-.468-1.906-.468ZM271.55 136.25h-5.53l-1.72-12.235h-10.437l-1.562 12.235h-5.531l9.421-59.781h5Zm-12.78-50.735-4.282 33.829h9.11ZM288.39 136.25h-5.077V76.469h7.171l8.891 47.312V76.47h5.063v59.78h-6.782l-9.265-48.562ZM339.115 96.578h-5.61V83.562c0-.988-.148-1.64-.437-1.953-.281-.312-.914-.469-1.89-.469h-4.297c-.993 0-1.641.157-1.954.47-.304.312-.453.964-.453 1.952v45.672c0 .992.149 1.64.453 1.953.313.313.961.47 1.954.47h4.296c.977 0 1.61-.157 1.891-.47.29-.312.438-.96.438-1.953v-12.937h5.609v13.797c0 4.156-2.106 6.234-6.313 6.234h-7.64c-4.156 0-6.235-2.078-6.235-6.234V82.703c0-4.156 2.079-6.234 6.235-6.234h7.64c4.207 0 6.313 2.078 6.313 6.234ZM368.814 103.984V76.47h5.531v59.78h-5.531v-27.593h-10.453v27.594h-5.531V76.469h5.53v27.515Zm0 0"
    />
    <path
      fill="#1e5100"
      d="M411.067 136.25c-4.167 0-6.25-2.078-6.25-6.235V119.11h5.547v10.047c0 .992.157 1.633.47 1.922.312.281.956.422 1.937.422h3.515c.989 0 1.633-.14 1.938-.422.312-.29.468-.93.468-1.922v-17.844c0-1.039-.148-1.703-.437-1.984-.281-.29-.938-.438-1.969-.438h-5.375c-4.156 0-6.234-2.078-6.234-6.234V82.703c0-4.156 2.078-6.234 6.234-6.234h7c4.164 0 6.25 2.078 6.25 6.234V95.64h-5.547v-12c0-1.039-.14-1.703-.422-1.984-.28-.289-.945-.437-1.984-.437h-3.594c-.98 0-1.625.148-1.937.437-.313.281-.469.945-.469 1.984v18.157c0 .992.156 1.64.469 1.953.312.312.957.469 1.937.469h5.375c4.164 0 6.25 2.078 6.25 6.234v19.562c0 4.157-2.086 6.235-6.25 6.235ZM452.91 76.469h5.61l-9.266 39.75v20.03h-5.546v-19.718L434.05 76.47h5.61l6.86 33.671ZM474.583 136.25h-5.078V76.469h7.172l8.89 47.312V76.47h5.063v59.78h-6.78l-9.267-48.562ZM525.308 96.578h-5.61V83.562c0-.988-.148-1.64-.437-1.953-.282-.312-.914-.469-1.89-.469h-4.298c-.992 0-1.64.157-1.953.47-.305.312-.453.964-.453 1.952v45.672c0 .992.148 1.64.453 1.953.313.313.961.47 1.953.47h4.297c.977 0 1.61-.157 1.89-.47.29-.312.438-.96.438-1.953v-12.937h5.61v13.797c0 4.156-2.106 6.234-6.313 6.234h-7.64c-4.157 0-6.235-2.078-6.235-6.234V82.703c0-4.156 2.078-6.234 6.234-6.234h7.641c4.207 0 6.313 2.078 6.313 6.234Zm0 0"
    />
    <path
      fill="none"
      stroke="#1e5100"
      strokeWidth={2.71731}
      d="M199.824 173.18H539.25M199.824 40.375H539.25"
    />
    <path
      fill="#458621"
      d="M146.776 179.02c-.277-1.152-.554-2.309-1.281-2.98-1.473-2.043-4.508-2.16-7.078-2.293-11.192-.043-19.774-5.079-23.906-15.641-.243-.227-.25-.457-.266-.926-.242-.222-.254-.453-.262-.687l-.535-1.844c-1.101-4.387-1.043-9.05-1.68-13.457l-.058-1.629c-.27-.922-.313-2.086-.824-3.234-2.125-12.973-7.508-25.82-16.047-35.75-.73-.907-1.7-1.801-1.75-3.2-.324-2.316 2.898-3.37 4.254-4.59 4.96-4.382 7.996-10.328 9.386-16.675 1.82-7.293 1.743-15.45.063-22.844-3.313-13.629-12.117-30.543-27.18-34.398-9.652-2.43-19.957-3.668-29.965-3.29a94.311 94.311 0 0 0-16.238 2.016c-2.77.805-5.535 1.61-7.086 3.766-1.988 2.875-1.137 6.804-.308 10.27C29.268 43.628 30.9 55.921 31.147 68.5c.043 7.227-1.554 14.278-2.918 21.325l.18 4.656c2.473 9.93 4.969 20.324 5.59 30.555.73 13.027-1.129 25.453-2.969 38.343-.2.938-.379 2.344.348 3.016.48.45 1.187.652 1.664.871 5.648 1.414 12.629 1.149 18.683.918 3.493-.133 18.81-1.879 20.79-4.984.671-.727.625-1.89.59-2.82-.106-2.794-.903-5.329-1.473-8.102-2.727-10.387-4.29-20.817-5.16-31.508-.055-1.395.074-4.2 2.168-4.277 4.187-.16 9.394 14.093 10.164 15.93 4.156 11.265 3.882 22.464 5.507 34.523 1.536 9.73 5.856 19.125 12.434 26.566 8.758 9.692 27.746 12.465 40.629 14.07 1.637.172 3.758.79 5.387.731 1.398-.055 2.554-.332 3.43-1.762 1.1-1.676 1.003-4.234 1.398-6.117.144-2.336.523-4.68.426-7.242a72.77 72.77 0 0 0-1.239-14.172ZM77.19 71.645c-2.636 4.297-7.226 6.102-12.117 6.29l-.7.026c-.464.016-.706-.207-.944-.433-.485-.445-.504-.91-.754-1.367-2.18-8.309-2.051-17.172-.508-25.621.207-.707.414-1.415.86-1.899.456-.25.921-.27 1.62-.297 12.094-.691 19.172 13.723 12.543 23.301Zm0 0"
    />
    <path
      fill="#1e5100"
      d="M140.93 166.557c2.328.098 4.421.188 6.527.043 3.984-.531 8-1.762 11.559-3.242 3.093-1.504 6.886-2.973 9.312-5.203 1.941-1.785 3.203-4.063 4.23-6.352 4.66-10.992 5.31-26.351 1.114-37.488-2.723-7.34-13.235-23.402-18.344-29.45-2.672-2.91-5.098-6.273-7.527-9.41l-3.985-5.062c-.66-.96-4.03-3.898-3.984-5.062-.879-1.204-1.984-2.649-2.625-4.075-1.715-3.57-1.54-7.757-.684-11.453.555-2.074 1.582-4.363 3.27-5.691.96-.66 2.379-1.067 3.543-1.016 1.86.078 3.46.844 4.566 2.29 1.762 2.406 1.39 5.656.801 8.66-.312 1.851-.867 3.93.23 5.605 1.114 1.215 2.954 1.758 4.817 1.836 2.785.351 5.574.469 8.601.594 2.793.12 5.586.238 8.387.12 2.57-.124 8.863-.093 9.711-3.554 1.086-3.684-.629-7.254-1.402-11.016-1.762-8-4.305-19.53-11.313-24.722-4.926-4.871-17.191-7.02-23.707-7.293-26.996-1.14-37.215 14.515-38.73 39.398-.176 4.192-.13 8.621.867 12.625 2.43 8.73 9.855 15.106 15.621 22.11 6.445 7.5 11.434 16.336 16.191 25.16 2.375 4.531 7.383 12.902 4.364 18.136-.996 1.59-2.422 2.23-3.82 2.172-3.024-.129-6.387-3.3-6.485-6.57-.113-2.8.691-5.332 1.043-8.113.156-3.727-.746-4.461-4.207-5.309a101.728 101.728 0 0 0-11.84-1.2c-4.422-.183-9.086-.148-13.305.376-2.812.348-4.015 1.226-3.91 4.262.04 4.664 1.48 9.156 1.285 13.812.079 14.692 6.766 27.563 21.258 32.137a37.99 37.99 0 0 0 7.188 1c3.71.39 7.426.781 10.918.926Zm0 0"
    />
  </svg>
);
