import React, { createContext, useContext, useState, ReactNode } from "react";
import { Snackbar } from "../components";

export type AlertType = "success" | "info" | "warning" | "error" | "default";

type SnackbarContextType = {
  openSnackbar: (msg: string, type?: AlertType) => void;
  closeSnackbar: () => void;
};

const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined
);

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context)
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  return context;
};

type ProviderProps = {
  children: ReactNode;
};

export const SnackbarProvider = ({ children }: ProviderProps) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [type, setType] = useState("default" as AlertType);

  const openSnackbar = (msg: string, type: AlertType = "default") => {
    setMessage(msg);
    setType(type);
    setOpen(true);
  };

  const closeSnackbar = () => {
    setOpen(false);
  };

  return (
    <SnackbarContext.Provider value={{ openSnackbar, closeSnackbar }}>
      {children}
      <Snackbar
        open={open}
        message={message}
        type={type}
        onClose={closeSnackbar}
      />
    </SnackbarContext.Provider>
  );
};
