import { MapSettings } from "../models/map";

export const DaysOfWeek = [
  {
    label: "Mon",
    value: "monday",
  },
  {
    label: "Tue",
    value: "tuesday",
  },
  {
    label: "Wed",
    value: "wednesday",
  },
  {
    label: "Thur",
    value: "thursday",
  },
  {
    label: "Fri",
    value: "friday",
  },
  {
    label: "Sat",
    value: "saturday",
  },
  {
    label: "Sun",
    value: "sunday",
  },
];

// Define all text variants
export enum TextVariant {
  DisplayLarge = "display-lg",
  DisplayMedium = "display-md",
  DisplaySmall = "display-sm",
  HeadlineLarge = "headline-lg",
  HeadlineMedium = "headline-md",
  HeadlineSmall = "headline-sm",
  TitleLarge = "title-lg",
  TitleMedium = "title-md",
  TitleSmall = "title-sm",
  LabelLarge = "label-lg",
  LabelMedium = "label-md",
  LabelSmall = "label-sm",
  BodyLarge = "body-lg",
  BodyMedium = "body-md",
  BodySmall = "body-sm",
  // html tags
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
  H4 = "h4",
  H5 = "h5",
  H6 = "h6",
  Label = "label",
  Paragraph = "p",
}

export const defaultAddress = {
  line1: "",
  line2: "",
  city: "",
  state: "",
  zip: "",
  country: "",
  lng: -98.5795,
  lat: 39.8283,
};

export const STATES_MAP: { [key: string]: string } = {
  Alabama: "AL",
  Alaska: "AK",
  Arizona: "AZ",
  Arkansas: "AR",
  California: "CA",
  Colorado: "CO",
  Connecticut: "CT",
  Delaware: "DE",
  Florida: "FL",
  Georgia: "GA",
  Hawaii: "HI",
  Idaho: "ID",
  Illinois: "IL",
  Indiana: "IN",
  Iowa: "IA",
  Kansas: "KS",
  Kentucky: "KY",
  Louisiana: "LA",
  Maine: "ME",
  Maryland: "MD",
  Massachusetts: "MA",
  Michigan: "MI",
  Minnesota: "MN",
  Mississippi: "MS",
  Missouri: "MO",
  Montana: "MT",
  Nebraska: "NE",
  Nevada: "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  Ohio: "OH",
  Oklahoma: "OK",
  Oregon: "OR",
  Pennsylvania: "PA",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  Tennessee: "TN",
  Texas: "TX",
  Utah: "UT",
  Vermont: "VT",
  Virginia: "VA",
  Washington: "WA",
  "West Virginia": "WV",
  Wisconsin: "WI",
  Wyoming: "WY",
};

export const VinRegex = /^RRV\d(DH|DE)\d{4}(-D|-S)?$/;

export const DefaultMapSettings: MapSettings = {
  center: {
    lng: -98.5795,
    lat: 39.8283,
  },
  zoom: 14.75,
  style: "mapbox://styles/hxlloghxst/clsuxnrn3006i01p42o6res1o",
};
