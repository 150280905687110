import React from "react";
import { Snackbar as MuiSnackbar, Alert, AlertColor } from "@mui/material";

type SnackbarProps = {
  open: boolean;
  message: string;
  onClose: () => void;
  type: AlertType;
};

export type AlertType = "success" | "info" | "warning" | "error" | "default";

const Snackbar = ({ open, message, onClose, type }: SnackbarProps) => {
  const getAlertClassName = (type: AlertType) => {
    switch (type) {
      case "success":
        return "!tracking-widest !font-medium !bg-smooth-primary !text-smooth-white";
      case "error":
        return "!tracking-widest !font-medium !bg-smooth-error !text-smooth-white";
      case "warning":
        return "!tracking-widest !font-medium !bg-smooth-warning !text-smooth-white";
      case "info":
        return "!tracking-widest !font-medium !bg-smooth-tertiary !text-smooth-white";
      default:
        return "!tracking-widest !font-medium !bg-smooth-neutral !text-smooth-white";
    }
  };
  return (
    <MuiSnackbar open={open} autoHideDuration={6000} onClose={onClose}>
      <Alert
        variant="filled"
        onClose={onClose}
        className={getAlertClassName(type)}
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </MuiSnackbar>
  );
};

export default Snackbar;
