import classNames from "classnames";
import React, { ReactNode } from "react";

export interface ButtonProps {
  children?: ReactNode;
  label?: string;
  onClickHandler?: () => void;
  className?: string;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
}

const Button = ({
  children,
  label,
  onClickHandler,
  className,
  disabled,
  type = "button",
}: ButtonProps) => {
  const buttonClasses = classNames(
    "btn normal-case rounded-lg tracking-widest",
    className
  );
  return (
    <button
      type={type}
      onClick={onClickHandler}
      className={buttonClasses}
      disabled={disabled}
    >
      {children || label}
    </button>
  );
};

export default Button;
