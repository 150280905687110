import axios from "axios";
import { IWeather, Location } from "../models/weather";

export interface WeatherResponse {
  data: IWeather;
}

const API_KEY = String(process.env.REACT_APP_WEATHER_API_KEY);
const BASE_URL = "https://api.weatherapi.com/v1";

export const getWeatherByLocation = async (location: Partial<Location>) =>
  await axios.get(`${BASE_URL}/current.json`, {
    params: {
      key: API_KEY,
      q: `${location.lat},${location.lon}`,
    },
  });
