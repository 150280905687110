import { Socket, io } from "socket.io-client";

class SocketIOClient {
  private static instance: SocketIOClient;
  private socket: Socket;
  private roomId?: string;

  private constructor() {
    this.socket = io(String(process.env.REACT_APP_SERVER_URL), {
      reconnectionAttempts: 5,
      reconnectionDelay: 3000,
    });
    this.initializeListeners();
  }

  public static getInstance(): SocketIOClient {
    if (!SocketIOClient.instance) {
      SocketIOClient.instance = new SocketIOClient();
    }
    return SocketIOClient.instance;
  }

  private initializeListeners(): void {
    this.socket.on("connect", () => {
      console.log("Socket.IO Client Connected");
      // attempt to join room
      if (this.roomId) {
        this.joinRoom(this.roomId);
      }
    });

    this.socket.on("disconnect", (reason) => {
      console.error(`Socket.IO Client Disconnected: ${reason}`);
    });

    this.socket.on("connect_error", (error) => {
      console.error(`Connection Error: ${error.message}`);
    });

    // Automatically attempt to reconnect on connection failure
    this.socket.on("reconnect_attempt", (attempt) => {
      console.log(`Reconnect Attempt ${attempt}`);
    });
  }

  public setRoomId(roomId: string): void {
    this.roomId = roomId;
    if (this.socket.connected) {
      this.joinRoom(roomId);
    }
  }

  public clearRoomId(): void {
    if (this.roomId) {
      this.leaveRoom(this.roomId);
      this.roomId = undefined;
    }
  }

  private joinRoom(roomId: string): void {
    console.log("Socket.IO Client JOINED ROOM ", roomId);
    this.socket.emit("join_room", roomId);
  }

  private leaveRoom(roomId: string): void {
    this.socket.emit("leave_room", roomId);
  }

  public onEvent<T>(event: string, action: (data: T) => void): void {
    this.socket.on(event, (data: T) => {
      action(data);
    });
  }

  public emitEvent<T>(event: string, data: T): void {
    this.socket.emit(event, data);
  }
}

export const ioClient = SocketIOClient.getInstance();
