import { observable, action, flow, makeObservable, computed } from "mobx";
import { IPasture } from "../../models/pasture";
import api from "../../api";
import BaseStore from "./BaseStore";
import RanchStore from "./RanchStore";
import { PastureResponse } from "../../api/pasture";

/**
 * PastureStore manages the state and operations related to pastures in the application.
 * It utilizes MobX for reactive state management, enabling efficient and scalable data handling.
 */
class PastureStore extends BaseStore<IPasture> {
  ranchStore: RanchStore;
  pastures = new Map<string, IPasture>();
  activePasture: IPasture | null = null;

  constructor(ranchStore: RanchStore) {
    super();
    this.ranchStore = ranchStore;
    makeObservable(this, {
      activePasture: observable,
      setActivePasture: action,
      reset: action,
      getActivePasture: computed,
      fetchPastures: flow,
      addPasture: flow,
      updatePasture: flow,
      deletePasture: flow,
    });
  }

  /**
   * Sets the active pasture
   */
  setActivePasture(id: string | null): void {
    if (id) {
      this.activePasture = this.pastures.get(id) || null;
    }
    this.activePasture = null;
  }

  /**
   * Fetches pastures for a specific ranch.
   * @param {string} ranchID - The ID of the ranch for which to fetch pastures.
   */
  *fetchPastures() {
    this.setLoading(true);
    try {
      // first get ranch from the store
      const ranch = this.ranchStore.getEntity;
      // once we have the ranch, fetch the pastures from the server
      if (ranch) {
        const { data }: PastureResponse = yield api.getPasturesByRanch(
          ranch?.ranch_id
        );
        if (data && data.pastures) {
          // set the pastures in the store
          data.pastures.forEach((pasture: IPasture) => {
            this.pastures.set(pasture.pasture_id, pasture);
          });
        }
      }
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  /**
   * Adds a new pasture.
   * @param {Partial<IPasture>} newPasture - The data for the new pasture.
   */
  *addPasture(newPasture: Partial<IPasture>) {
    this.setLoading(true);
    try {
      if (!newPasture?.ranch_id) {
        throw new Error(
          "Error Creating Pasture: Ranch ID must be provided in payload"
        );
      }
      const { data } = yield api.createPasture(newPasture);
      if (data && data.pastures) {
        this.pastures.set(data.pasture.pasture_id, data.pasture);
      }
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  /**
   * Updates an existing pasture.
   * @param {string} id - The ID of the pasture to update.
   * @param {Partial<IPasture>} updatedPasture - The updated pasture data.
   */
  *updatePasture(id: string, updatedPasture: Partial<IPasture>) {
    this.setLoading(true);
    try {
      const { data } = yield api.updatePasture(id, updatedPasture);
      if (data && data.pastures) {
        this.pastures.set(data.pasture.pasture_id, data.pasture);
      }
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  /**
   * Deletes a pasture.
   * @param {string} id - The ID of the pasture to delete.
   */
  *deletePasture(id: string) {
    this.setLoading(true);
    try {
      yield api.deletePasture(id);
      this.pastures.delete(id);
      this.setActivePasture(null);
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  /**
   * Resets the store to its initial state.
   */
  reset(): void {
    this.setLoading(false);
    this.setError(null);
    this.setEntity(null);
  }

  /**
   * Get a pasture from the map with the provided ID
   */
  get getActivePasture(): IPasture | null {
    return this.activePasture;
  }

  /**
   * Get all pastures in store
   */
  get getAllPastures(): IPasture[] {
    return Array.from(this.pastures.values());
  }
}

export default PastureStore;
