import React, { ReactNode } from "react";
import classNames from "classnames";

interface SectionProps {
  children: ReactNode;
  className?: string;
}

const Section = ({ children, className }: SectionProps) => {
  const sectionClasses = classNames("mx-auto py-4", className);

  return <section className={sectionClasses}>{children}</section>;
};

export default Section;
