import {
  To,
  useNavigate as useBaseNavigate,
  NavigateOptions,
} from "react-router-dom";

export const useNavigate = () => {
  const navigate = useBaseNavigate();

  return (to: To, options?: NavigateOptions | undefined) => {
    navigate(to, options);
  };
};
