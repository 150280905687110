import React, { ReactNode, useEffect } from "react";
import { animated } from "react-spring";
import classNames from "classnames";
import SideSheet from "./SideSheet";
import { useBottomSheet } from "../../context";
import { useSheetAnimation } from "./context";

interface BottomSheetProps {
  onClose?: () => void;
  children?: ReactNode;
}

const BottomSheet = ({ onClose, children }: BottomSheetProps) => {
  const {
    heightAnimation,
    slideAnimation,
    sideSheetAnimation,
    toggleSideSheet,
    contentRef,
    sideSheetRef,
  } = useSheetAnimation();

  const {
    isBottomSheetVisible,
    bottomSheetContent,
    hideBottomSheet,
    hideSideSheet,
    isSideSheetVisible,
    sideSheetContent,
  } = useBottomSheet();

  useEffect(() => {
    toggleSideSheet(isSideSheetVisible);
  }, [isSideSheetVisible, toggleSideSheet]);

  useEffect(() => {
    const bodyStyle = document.body.style;
    if (isBottomSheetVisible) {
      bodyStyle.overflow = "hidden";
    } else {
      bodyStyle.overflow = "unset";
    }
  }, [isBottomSheetVisible]);

  // Close handlers
  const handleClose = () => {
    hideBottomSheet();
    if (onClose) onClose();
  };

  const handleSideSheetClose = () => {
    hideSideSheet();
  };

  const containerClasses = classNames(
    "fixed inset-0 bg-smooth-neutral-variant-40 bg-opacity-50 z-40 transition-opacity overflow-hidden",
    {
      "opacity-100": isBottomSheetVisible,
      "opacity-0 pointer-events-none": !isBottomSheetVisible,
    }
  );
  const sheetClasses = classNames(
    "fixed bottom-0 left-0 right-0 bg-smooth-primary-100 rounded-t-3xl px-4 pt-1 pb-4 transform transition-transform",
    {
      "translate-y-0": isBottomSheetVisible,
      "translate-y-full": !isBottomSheetVisible,
    }
  );

  return (
    <div className={containerClasses} onClick={handleClose}>
      <animated.div
        className={sheetClasses}
        style={heightAnimation}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="relevant pb-6 bg-smooth-smooth-primary-100">
          <div
            className="handle-container bg-smooth-white z-20 flex justify-center pt-4 pb-6 rounded-t-3xl absolute w-full top-0 left-0"
            onClick={handleClose}
          >
            <span className="handle w-8 h-1 bg-smooth-neutral-variant-50 opacity-40 rounded-full" />
          </div>
          <animated.div
            ref={contentRef}
            style={slideAnimation}
            className="absolute w-full top-0 left-0 pt-8 max-h-[85vh] overflow-x-hidden overflow-y-scroll"
          >
            <div className="p-4 overflow-visible">
              {bottomSheetContent || children}
            </div>
          </animated.div>
          <animated.div
            ref={sideSheetRef}
            style={sideSheetAnimation}
            className="absolute w-full top-0 left-0 pt-8"
          >
            <div className="p-4">
              <SideSheet
                isOpen={isSideSheetVisible}
                onClose={handleSideSheetClose}
              >
                {sideSheetContent}
              </SideSheet>
            </div>
          </animated.div>
        </div>
      </animated.div>
    </div>
  );
};

export default BottomSheet;
