import { CSSProperties } from "react";
import { Battery, Odometer } from "../../Icons";
import { EquipmentVitals } from "../../models/vitals";
import { TextVariant } from "../../utils/const";
import Text from "../Text";
import classNames from "classnames";
import Tile from "./Tile";

export type TileStatus = {
  type: "active" | "inactive";
  label: string;
};
interface ProgressTileProps {
  title: string;
  status?: TileStatus;
  vitals?: EquipmentVitals;
  progress?: {
    type: "linear" | "circular";
    value: number;
  };
  onClickHandler?: () => void;
}

const ProgressTile = ({
  title,
  status,
  vitals,
  progress,
  onClickHandler,
}: ProgressTileProps) => {
  const labelClasses = classNames("card-title", {
    "text-smooth-primary-50": status?.type === "active",
    "text-smooth-neutral-30": status?.type === "inactive",
  });

  return (
    <Tile onClickHandler={onClickHandler}>
      {status && (
        <Text variant={TextVariant.TitleMedium} className={labelClasses}>
          {status?.label}
        </Text>
      )}
      {vitals && (
        <ul className="flex justify-between m-0 p-0">
          {vitals?.battery && (
            <li className="flex items-center gap-1 mt-2">
              <Battery />
              <Text
                variant={TextVariant.TitleMedium}
                className="text-smooth-neutral-70 text-title-lg-alt"
              >
                {vitals?.battery}%
              </Text>
            </li>
          )}
          {vitals?.speed && (
            <li className="flex items-center gap-0.5 mt-2">
              <Odometer />
              <Text
                variant={TextVariant.TitleMedium}
                className="text-smooth-neutral-70 text-title-lg-alt"
              >
                {vitals?.speed}mph
              </Text>
            </li>
          )}
        </ul>
      )}
      {progress?.type !== "circular" && (
        <Text variant={TextVariant.TitleLarge} className="font-medium">
          {title}
        </Text>
      )}
      {progress?.type === "linear" && (
        <progress
          className={`progress progress-primary w-${progress?.value}/100`}
          value={progress?.value}
          max="100"
        />
      )}
      {progress?.type === "circular" && (
        <div
          className="radial-progress text-smooth-primary-60 bg-smooth-secondary-90/30 self-center"
          style={
            {
              "--value": progress?.value || 0,
              "--size": "6.5rem",
              "--thickness": "4px",
            } as CSSProperties
          }
        >
          <Text variant={TextVariant.BodyLarge} className="text-center">
            {title}
          </Text>
          <Text
            variant={TextVariant.HeadlineMedium}
            className="text-center font-normal"
          >
            {progress?.value}%
          </Text>
        </div>
      )}
    </Tile>
  );
};

export default ProgressTile;
