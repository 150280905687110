import classNames from "classnames";

interface TabSwitcherProps {
  tabs: {
    id: string;
    value: string;
  }[];
  activeTab: string;
  onTabChange: (tab: string) => void;
}

const TabSwitcher = ({ tabs, activeTab, onTabChange }: TabSwitcherProps) => {
  return (
    <div className="flex gap-2 border-b border-smooth-neutral-90">
      {tabs.map((tab, idx) => {
        const first = idx === 0;
        const last = tabs.length - 1 === idx;
        const tabActive = activeTab === tab?.id;
        const tabClasses = classNames(
          "btn relative border-0 px-4 btn-ghost normal-case font-medium rounded-none",
          {
            "pl-0": first,
            "pr-0": last,
            "text-smooth-primary-50": tabActive,
            "text-smooth-neutral-variant": !tabActive,
          }
        );
        const borderClasses = classNames(
          "absolute bottom-0 w-8/12 bg-smooth-primary-50 block rounded-t transition-all",
          {
            "h-1": tabActive,
            "h-0": !tabActive,
          }
        );

        return (
          <button
            key={tab?.id}
            className={tabClasses}
            onClick={() => onTabChange(tab?.id)}
          >
            {tab?.value}
            <span className={borderClasses} />
          </button>
        );
      })}
    </div>
  );
};

export default TabSwitcher;
