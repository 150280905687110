import React, { Children, ReactNode } from "react";
import Slider, { Settings } from "react-slick";

type CarouselProps = {
  children: ReactNode;
  settings?: Partial<Settings>;
};

const Carousel = ({ children, settings }: CarouselProps) => {
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    swipeToSlide: true,
    arrows: false,
    touchThreshold: 50,
    draggable: true,
    responsive: [
      {
        breakpoint: 340,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
    ],
    ...settings,
  };

  return (
    <Slider {...sliderSettings} className="pasture-slider">
      {Children.map(children, (child, index) => (
        <div key={index} className="pr-4">
          {child}
        </div>
      ))}
    </Slider>
  );
};

export default Carousel;
