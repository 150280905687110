import React from "react";
import classNames from "classnames";
import { Button, PrimaryButton } from "../Button";
import { TrashCan } from "../../Icons";

interface ActionButtonsProps {
  onCancel: () => void; // Click handler for the Cancel button
  onSave?: () => void; // Optional Click handler for the Save button
  onDelete?: () => void; // Optional Click handler for the Delete button
  cancelLabel?: string; // Optional prop to customize the Cancel button label
  saveLabel?: string; // Optional prop to customize the Save button label
  cancelClassName?: string; // Optional prop to customize the Cancel button styling
  saveClassName?: string; // Optional prop to customize the Save button styling
  isSubmitting?: boolean; // Optional prop to handle form submission state
}

const ActionButtons = ({
  onCancel,
  onSave,
  onDelete,
  cancelLabel = "Cancel",
  saveLabel = "Save",
  cancelClassName = "",
  isSubmitting = false,
}: ActionButtonsProps) => {
  const cancelClasses = classNames(
    "btn btn-outline btn-primary border-smooth-neutral-variant-50",
    cancelClassName
  );
  return (
    <>
      <div className="grid grid-cols-3 gap-2">
        <Button
          type="button"
          onClickHandler={onCancel}
          className={cancelClasses}
        >
          {cancelLabel}
        </Button>
        <PrimaryButton
          type={!onSave ? "submit" : "button"}
          onClickHandler={onSave}
          disabled={isSubmitting}
          className="col-span-2"
        >
          {saveLabel}
        </PrimaryButton>
        {onDelete && (
          <div className="pt-2.5 col-span-3">
            <Button
              type="button"
              onClickHandler={onDelete}
              className="w-full btn btn-outline btn-error"
            >
              <TrashCan />
              Delete
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default ActionButtons;
