import React from "react";
import { JSX } from "react/jsx-runtime";
import { SVGIconProps } from "../types";

export const PrimaryLogo = ({
  width = 50,
  height = 50,
  className,
}: SVGIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 37.5 37.5"
    height={height}
    width={width}
    className={className}
  >
    <path
      fill="#458621"
      d="M6.152-.066c.336 0 .633 0 .934-.036a6.527 6.527 0 001.629-.53c.433-.231.965-.466 1.297-.798.265-.265.433-.597.566-.933.598-1.594.598-3.79-.066-5.356-.434-1.031-2.028-3.258-2.793-4.09-.399-.398-.766-.867-1.133-1.296l-.598-.7c-.101-.133-.597-.53-.597-.699-.133-.168-.301-.367-.403-.566-.265-.5-.265-1.098-.164-1.63.067-.3.2-.632.434-.831a.88.88 0 01.496-.168c.266 0 .5.101.668.3.266.333.23.797.164 1.231-.031.266-.098.566.066.8.168.165.434.231.7.231.398.035.796.035 1.23.035.398 0 .8 0 1.2-.035.363-.031 1.261-.066 1.363-.566.132-.531-.133-1.031-.266-1.563-.3-1.129-.734-2.761-1.766-3.46-.73-.665-2.492-.895-3.425-.895-3.86 0-5.223 2.293-5.29 5.851 0 .602.036 1.23.2 1.797.398 1.23 1.5 2.098 2.363 3.063.965 1.031 1.73 2.262 2.46 3.492.368.633 1.134 1.797.731 2.563-.132.23-.332.332-.53.332-.43 0-.93-.434-.966-.899-.031-.398.067-.766.102-1.164 0-.531-.133-.633-.633-.734a14.56 14.56 0 00-1.695-.098c-.633 0-1.297.031-1.899.133-.398.066-.566.2-.531.633.035.664.266 1.297.266 1.96C.367-2.593 1.398-.796 3.492-.233c.332.066.7.101 1.031.101.532.031 1.067.067 1.563.067zm0 0"
      transform="translate(3.33 26.848)"
    ></path>
    <path
      fill="#1e5100"
      d="M16.668 6.32c.762.102 1.562.168 2.328.168.832 0 1.629-.066 2.426-.265.867-.203.5-1.332.168-1.864-1.695-2.597-4.324-9.414-4.625-11.476-.164-1.031-.164-1.93-.067-2.996 0-.235.102-.434 0-.664-.066-.2-.23-.266-.332-.399-.765-.832-1.695-2.863-2.093-4.058-.5-1.832-.899-4.028.066-5.758.066-.067.098-.133.066-.23 0-.102-.168-.169-.3-.2-1.23-.265-2.528-.336-3.793-.336-1.23 0-3.227.035-4.36.403-.132.066-.297.132-.332.265-.03.098 0 .2.035.297.899 2.563-.367 5.758-1.43 8.086-.234.5-.5.996-.866 1.43-.23.234-.832.597-.73.898.398 1.098.398 2.297.198 3.395C2.63-4.524 1.597-2.13.133-.102c-.098.137-.2.336-.067.434.036.035.102.035.168.035.164.031.332.031.496.031 1.567 0 3.094 0 4.625-.03.266 0 .567-.036.832-.169A.626.626 0 006.52-.5c-.098-.363-.133-.7-.067-1.063.067-.632.367-1.23.934-1.496a7.655 7.655 0 011.93-.468c.199-.032.363-.032.562-.032.734 0 1.43.133 2.129.266 3.195.73 3.695 7.219 3.66 8.117 0 .2-.133 1.031 0 1.23.066.133.266.169.398.169zM8.618-7.852c-.067-.132-.067-.296-.067-.464.097-1.829.996-4.493 1.629-6.188a26.815 26.815 0 011.465 4.79c.132.632.664 1.765-.168 2.062-.368.132-.766.168-1.165.168-.367 0-.765-.036-1.164-.102-.168 0-.3-.031-.398-.133a.246.246 0 01-.133-.133zm0 0"
      transform="translate(12.613 26.848)"
    ></path>
  </svg>
);
