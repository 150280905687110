import { observer } from "mobx-react";
import { useEffect } from "react";
import { useSnackbar, useStores } from "../../context";

const ErrorListener = observer(() => {
  const { openSnackbar } = useSnackbar();
  const { errorStore } = useStores();
  const errors = errorStore.getErrors;
  const latestErr = errors[errors.length - 1];

  useEffect(() => {
    const disposer =
      errorStore.getErrors.length > 0 &&
      openSnackbar(latestErr.message, "error");

    return () => {
      if (disposer) errorStore.clearErrors();
    };
  }, [errorStore, latestErr, openSnackbar]);

  return null;
});

export default ErrorListener;
