import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import classNames from "classnames";

interface CheckboxButtonProps {
  name: string;
  label: string;
  className?: string;
  value: string;
}

const CheckboxButton: React.FC<CheckboxButtonProps> = ({
  name,
  label,
  className,
  value,
}) => {
  const { control } = useFormContext();

  const checkboxClassNames = (isChecked: boolean) =>
    classNames(
      "relative flex items-center justify-center rounded-lg px-5 py-3.5 border",
      className,
      {
        "bg-smooth-secondary-90 border-smooth-primary": isChecked,
        "border-smooth-neutral-variant-60 bg-smooth-secondary-100": !isChecked,
      }
    );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value: formValues } }) => {
        const isChecked = formValues.includes(value);
        return (
          <>
            <label className={checkboxClassNames(isChecked)}>
              <span
                className={`text-sm font-medium ${
                  isChecked
                    ? "text-smooth-secondary-10"
                    : "text-smooth-neutral-variant-30"
                }`}
              >
                {label}
              </span>
              <input
                type="checkbox"
                className="opacity-0 absolute"
                value={value}
                checked={isChecked}
                onBlur={onBlur}
                onChange={(e) => {
                  const setValues = e.target.checked
                    ? [...formValues, value]
                    : formValues.filter((v: string) => v !== value);
                  onChange(setValues);
                }}
              />
            </label>
          </>
        );
      }}
    />
  );
};

export default CheckboxButton;
