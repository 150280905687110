import jwtDecode from "jwt-decode";

interface SessionData {
  user_id: string;
  email: string;
  roles: string[];
  iat: number;
  exp: number;
}

const setStorageToken = (token: string) => {
  try {
    // verify token
    verifyToken(token);
    // set the token to the local storage
    localStorage.setItem("token", token);
  } catch (error) {
    console.error("Failed to set storage token:", error);
    removeStorageToken();
  }
};

const verifyToken = (token: string) => {
  const decodedToken: SessionData = jwtDecode(token);
  if (!decodedToken) {
    throw new Error("Failed to decode the JWT.");
  }

  if (
    !decodedToken.user_id ||
    !Array.isArray(decodedToken.roles) ||
    !decodedToken.iat ||
    !decodedToken.exp
  ) {
    throw new Error("Invalid JWT: required fields are missing.");
  }

  if (Date.now() >= decodedToken.exp * 1000) {
    throw new Error("Invalid JWT: the token has expired.");
  }
};

const getStorageToken = (): string | null => {
  return localStorage.getItem("token");
};

const removeStorageToken = (): void => {
  localStorage.removeItem("token");
};

const decodeToken = (token: string): SessionData | null => {
  try {
    const decodedToken: SessionData = jwtDecode(token);
    if (!decodedToken) {
      throw new Error("Failed to decode the JWT.");
    }

    if (
      !decodedToken.user_id ||
      !Array.isArray(decodedToken.roles) ||
      !decodedToken.iat ||
      !decodedToken.exp
    ) {
      throw new Error("Invalid JWT: required fields are missing.");
    }

    if (Date.now() >= decodedToken.exp * 1000) {
      throw new Error("Invalid JWT: the token has expired.");
    }

    return decodedToken;
  } catch (error) {
    console.error("Failed to decode the token:", error);
    return null;
  }
};

export {
  verifyToken,
  setStorageToken,
  removeStorageToken,
  getStorageToken,
  decodeToken,
};
