import AuthStore from "./AuthStore";
import CattleStore from "./CattleStore";
import HerdStore from "./HerdStore";
import PastureStore from "./PastureStore";
import RanchStore from "./RanchStore";
import RoverStore from "./RoverStore";
import WeatherStore from "./WeatherStore";
import EquipmentStore from "./EquipmentStore";
import RouteStore from "./RouteStore";
import MissionStore from "./MissionStore";
import ErrorStore from "./ErrorStore";

class RootStore {
  errorStore: ErrorStore;
  authStore: AuthStore;
  cattleStore: CattleStore;
  herdStore: HerdStore;
  pastureStore: PastureStore;
  ranchStore: RanchStore;
  weatherStore: WeatherStore;
  roverStore: RoverStore;
  equipmentStore: EquipmentStore;
  routeStore: RouteStore;
  missionStore: MissionStore;

  constructor() {
    this.errorStore = new ErrorStore();
    this.authStore = new AuthStore();
    this.ranchStore = new RanchStore(this.authStore);
    this.cattleStore = new CattleStore();
    this.herdStore = new HerdStore();
    this.weatherStore = new WeatherStore(this.ranchStore);
    this.pastureStore = new PastureStore(this.ranchStore);
    this.equipmentStore = new EquipmentStore(this.ranchStore);
    this.routeStore = new RouteStore(this.ranchStore);
    this.roverStore = new RoverStore(this.ranchStore);
    this.missionStore = new MissionStore(this.roverStore, this.routeStore);
  }
}

export default RootStore;
