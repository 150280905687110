import { IAddress } from "./address";

export enum UserRole {
  ADMIN = "ADMIN",
  RANCH_OWNER = "RANCH_OWNER",
  RANCH_HAND = "RANCH_HAND",
  BASE_USER = "BASE_USER",
  VETERINARIAN = "VETERINARIAN",
}

interface UserAttributes {
  user_id: string;
  address_id: string;
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  role: UserRole;
  completed_setup: boolean;
  created_at: Date;
  updated_at?: Date;
}

export interface IUser extends UserAttributes {
  address?: Partial<IAddress>;
}

export interface UserRegistration {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  role: UserRole;
}

export const getFullName = (user: UserAttributes | null) => {
  return user ? `${user.first_name} ${user.last_name}` : "";
};
