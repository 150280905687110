import React from "react";
import { Link } from "react-router-dom";

export type BreadCrumb = {
  label: string;
  linkTo: string;
  currentPath?: string;
};

interface IBreadCrumbs {
  breadcrumbs: BreadCrumb[];
}

const BreadCrumbs = ({ breadcrumbs }: IBreadCrumbs) => {
  return (
    <div className="text-sm breadcrumbs pb-0">
      <ul>
        {breadcrumbs.map(({ label, linkTo }) => (
          <li key={label}>
            <Link to={linkTo}>{label}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BreadCrumbs;
