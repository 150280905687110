import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { FullscreenControl, Marker, NavigationControl } from "mapbox-gl";
import { useMap } from "../../hooks";
import { useStores } from "../../context";
import { Skeleton } from "@mui/material";
import { RoverIcon } from "../../Icons";
import { createRoot } from "react-dom/client";
import MapMetadata from "./MapMetadata";

const RoverMap = observer(() => {
  const { roverStore, ranchStore } = useStores();
  const rover = roverStore.getEntity;
  const ranch = ranchStore.getEntity;
  const { map, mapContainer, mapLoading } = useMap({
    center:
      (rover?.data.coordinates && {
        ...rover?.data.coordinates,
      }) ||
      (ranch?.address && { ...ranch?.address }),
  });
  const markerRef = useRef<Marker>();

  // Initialize map controls
  useEffect(() => {
    console.log("here 1");
    if (!map || mapLoading) return;

    console.log("here 2");

    const fullScreenControl = new FullscreenControl();
    const navigationControl = new NavigationControl();
    map.addControl(navigationControl, "top-left");
    map.addControl(fullScreenControl, "top-right");

    if (ranch) {
      map.flyTo({
        center: { lng: ranch.address.lng, lat: ranch.address.lat },
        zoom: 15.5,
      });
    }

    return () => {
      console.log("here use effect return");
      map?.removeControl(navigationControl);
      map?.removeControl(fullScreenControl);
    };
  }, [map, mapLoading, ranch]);

  // Update marker position when rover coordinates change
  useEffect(() => {
    const coordinates = rover?.data.coordinates;
    if (map && coordinates) {
      if (!markerRef.current) {
        const el = document.createElement("div");
        el.style.display = "block";
        const root = createRoot(el);
        root.render(
          <>
            <div className="map-location">
              <div className="dot"></div>
              <div className="absolute">
                <RoverIcon />
              </div>
            </div>
          </>
        );

        markerRef.current = new Marker({
          element: el,
          anchor: "center",
        })
          .setLngLat([coordinates.lng, coordinates.lat])
          .addTo(map);
      }
      markerRef.current.setLngLat([coordinates.lng, coordinates.lat]);
    }
  }, [rover?.data.coordinates, map]);

  // show loading if we don't have entity
  if (!ranch || ranchStore.fetchingEntity) {
    return (
      <>
        <Skeleton variant="rounded" height={320} />
        <Skeleton
          className="my-4"
          variant="text"
          sx={{ fontSize: "2rem" }}
          width={100}
        />
      </>
    );
  }

  return (
    <div className="relative w-full">
      <div ref={mapContainer} className="map h-96 md:h-[500px] rounded-lg" />
      <MapMetadata />
    </div>
  );
});

export default RoverMap;
