export const NearMe = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <mask
      id="mask0_178_1506"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="18"
      height="18"
    >
      <rect width="18" height="18" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_178_1506)">
      <path
        d="M9.675 15.75L7.5375 10.4625L2.25 8.325V7.275L15.75 2.25L10.725 15.75H9.675ZM10.1625 12.975L13.2 4.8L5.025 7.8375L8.7 9.3L10.1625 12.975Z"
        fill="#458621"
      />
    </g>
  </svg>
);
