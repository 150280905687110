import { action, computed, makeObservable, observable } from "mobx";
import BaseStore from "./BaseStore";
import { ioClient } from "../../socket";

interface IError {
  code?: number;
  type?: string;
  message: string;
}

class ErrorStore extends BaseStore<IError> {
  errors: IError[] = [];
  constructor() {
    super();

    makeObservable(this, {
      errors: observable,
      addError: action,
      clearErrors: action,
      getErrors: computed,
    });

    this.setupSocketListeners();
  }

  reset(): void {
    this.setEntity(null);
  }

  clearErrors() {
    this.errors = [];
  }

  addError(error: IError) {
    this.errors.push(error);
  }

  setupSocketListeners = () => {
    ioClient.onEvent<{ error: IError }>("ERRORS", ({ error }) => {
      this.addError(error);
    });
  };

  get getErrors(): IError[] {
    return Array.from(this.errors.values()) || [];
  }
}

export default ErrorStore;
