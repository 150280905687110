import { IUser } from "../models/user";
import { Axios } from "./core";

const BASE_PATH = "/auth";

export type AuthResponse = {
  data: { user?: IUser; message?: string; token?: string };
};

export const registerUser = async (payload: {
  user: Partial<IUser>;
}): Promise<AuthResponse> =>
  await Axios.post(`${BASE_PATH}/register`, payload.user);

export const loginUser = async (payload: {
  email: string;
  password: string;
}): Promise<AuthResponse> => await Axios.post(`${BASE_PATH}/login`, payload);

export const logoutUser = async (payload: {
  token: string;
}): Promise<AuthResponse> => await Axios.post(`${BASE_PATH}/logout`, payload);

export const refreshToken = async (payload: {
  token: string;
}): Promise<AuthResponse> =>
  await Axios.post(`${BASE_PATH}/refresh-token`, payload);
