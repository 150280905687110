import FormControl from "../../FormControl";
import Text from "../../Text";
import { TextVariant } from "../../../utils/const";
import { Container, FormHelperText } from "@mui/material";
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";
import classNames from "classnames";
import { RadioButtonStacked } from "../RadioButton";

interface TimeInputProps {
  name: string;
  label: string;
}

interface NumberInputProps extends UseControllerProps {
  name: string;
  label: string;
}

const NumberInput = ({ name, label, rules }: NumberInputProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        const { value } = field;
        const styles = classNames(
          "text-center py-2.5 px-4 w-full border text-5xl rounded-lg transition-all outline-none outline-offset-0 focus:bg-smooth-white focus:outline-2 focus:outline-smooth-primary focus:border-transparent",
          {
            "border-transparent outline-smooth-error": error,
            "border-smooth-neutral-variant-60 bg-smooth-white": value,
            "border-transparent bg-smooth-neutral-90": !error && !value,
          }
        );
        return (
          <>
            <FormControl>
              <input {...field} type="number" name={name} className={styles} />
              <FormHelperText className="!text-smooth-base">
                {label}
              </FormHelperText>
            </FormControl>
          </>
        );
      }}
    />
  );
};

const TimeInput = ({ label, name }: TimeInputProps) => {
  return (
    <Container className="bg-smooth-neutral-98 py-4 rounded-lg">
      <FormControl>
        <Text
          variant={TextVariant.TitleSmall}
          className="font-medium text-sm mb-2"
        >
          {label}
        </Text>
      </FormControl>
      <div className="grid grid-flow-col gap-4">
        <div className="grid grid-flow-col gap-2 auto-cols-auto">
          <FormControl>
            <NumberInput
              name={`${name}.time.hour`}
              label="Hour"
              rules={{
                required: {
                  value: true,
                  message: "Must not be empty",
                },
              }}
            />
          </FormControl>
          <FormControl>
            <Text variant={TextVariant.BodyLarge} className="mt-1">
              <span className="text-6xl">&#58;</span>
            </Text>
          </FormControl>
          <FormControl>
            <NumberInput
              name={`${name}.time.minute`}
              label="Minute"
              rules={{
                required: {
                  value: true,
                  message: "Must not be empty",
                },
              }}
            />
          </FormControl>
        </div>
        <FormControl>
          <div className="join join-vertical rounded-lg">
            <RadioButtonStacked
              name={`${name}.daytime`}
              value="am"
              label="AM"
              // className="col-span-1"
            />
            <RadioButtonStacked
              name={`${name}.daytime`}
              value="pm"
              label="PM"
              // className="col-span-1"
            />
          </div>
        </FormControl>
      </div>
    </Container>
  );
};

export default TimeInput;
