import * as authAPI from "./auth";
import * as coreAPI from "./core";
import * as feedScheduleAPI from "./feed-schedule";
import * as feederAPI from "./feeder";
import * as missionAPI from "./mission";
import * as pastureAPI from "./pasture";
import * as ranchAPI from "./ranch";
import * as roverAPI from "./rover";
import * as userAPI from "./user";
import * as weatherAPI from "./weather";
import * as equipmentAPI from "./equipment";
import * as routesAPI from "./routes";

const api = {
  ...authAPI,
  ...ranchAPI,
  ...coreAPI,
  ...equipmentAPI,
  ...feedScheduleAPI,
  ...feederAPI,
  ...missionAPI,
  ...roverAPI,
  ...userAPI,
  ...pastureAPI,
  ...weatherAPI,
  ...routesAPI,
};

export default api;
