import React from "react";
import Button, { ButtonProps } from "./Button";
import classNames from "classnames";

const OutlineButton = ({
  children,
  onClickHandler,
  className,
}: ButtonProps) => {
  const styles =
    "btn-ghost border border-smooth-neutral-70 font-normal text-left tracking-wide text-base normal-case active:border-smooth-primary active:bg-smooth-primary-99 hover:border-smooth-primary hover:bg-smooth-primary-99";
  return (
    <Button
      className={classNames(styles, className)}
      onClickHandler={onClickHandler}
    >
      {children}
    </Button>
  );
};

export default OutlineButton;
