import classNames from "classnames";
import React, { ReactNode } from "react";

const FormControl = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  const styles = classNames("form-control w-full", className);

  return <div className={styles}>{children}</div>;
};

export default FormControl;
