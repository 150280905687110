import classNames from "classnames";
import React, { ReactNode } from "react";

const View = ({
  className,
  children,
}: {
  className?: string;
  children: ReactNode;
}) => {
  const viewClasses = classNames(
    "flex flex-col min-h-[calc(100vh-5rem)]",
    className
  );
  return <div className={viewClasses}>{children}</div>;
};

export default View;
