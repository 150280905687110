import { IRover } from "../models/rover";
import { Axios } from "./core";

const BASE_PATH = "/rovers";

export type RoverResponse = {
  data: { rover?: IRover; rovers?: IRover[]; message?: string };
};

export type WaveResponse = {
  data: { wave: boolean };
};

export const createRover = async (
  payload: Partial<IRover>
): Promise<RoverResponse> => await Axios.post(BASE_PATH, payload);

export const updateRover = async (
  roverID: string,
  payload: Partial<IRover>
): Promise<RoverResponse> =>
  await Axios.patch(`${BASE_PATH}/${roverID}`, payload);

export const deleteRover = async (roverID: string): Promise<void> =>
  await Axios.delete(`${BASE_PATH}/${roverID}`);

export const disableRover = async (roverID: string): Promise<void> =>
  await Axios.get(`${BASE_PATH}/disable-rover/${roverID}`);

export const enableRover = async (roverID: string): Promise<void> =>
  await Axios.get(`${BASE_PATH}/enable-rover/${roverID}`);

export const getRoverByID = async (roverID: string): Promise<RoverResponse> =>
  await Axios.get(`${BASE_PATH}/${roverID}`);

export const getRoversByRanch = async (
  ranchID: string
): Promise<RoverResponse> => await Axios.get(`${BASE_PATH}/ranch/${ranchID}`);

export const connectPhysicalRover = async (
  roverID: string,
  userID: string
): Promise<RoverResponse> =>
  await Axios.post(`${BASE_PATH}/${roverID}/connect/${userID}`);

export const waveToRover = async (roverID: string): Promise<WaveResponse> =>
  await Axios.get(`${BASE_PATH}/${roverID}/wave`);

export const toggleEngine = async (
  roverID: string,
  payload: { engine: boolean }
): Promise<RoverResponse> =>
  await Axios.post(`${BASE_PATH}/${roverID}/commands/toggle-engine`, payload);

export const toggleFeed = async (
  roverID: string,
  payload: { feed: boolean }
): Promise<RoverResponse> =>
  await Axios.post(`${BASE_PATH}/${roverID}/commands/toggle-feed`, payload);

export const runMission = async (missionId: string): Promise<RoverResponse> =>
  await Axios.post(`${BASE_PATH}/${missionId}/commands/run-mission`);

export const stopMission = async (missionId: string): Promise<RoverResponse> =>
  await Axios.post(`${BASE_PATH}/${missionId}/commands/stop-mission`);
