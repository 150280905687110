import { useCallback, useEffect, useRef, useState } from "react";
import { Map, Marker } from "mapbox-gl";
import { MapSettings } from "../models/map";
import { DefaultMapSettings } from "../utils";

export const useMap = (
  mapSettings: Partial<MapSettings>,
  onClickHandler?: (waypoint_id: string, lngLat: [number, number]) => void
) => {
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<Map | null>(null);
  const [mapLoading, setMapLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const initializeMap = useCallback(() => {
    if (!mapContainerRef.current || mapRef.current) return;

    const settings = { ...DefaultMapSettings, ...mapSettings };
    try {
      const token = String(process.env.REACT_APP_MAPBOX_ACCESS_TOKEN);
      mapRef.current = new Map({
        accessToken: token,
        container: mapContainerRef.current,
        style: settings?.style,
        center: settings?.center || DefaultMapSettings?.center,
        zoom: settings?.zoom,
      });

      const map = mapRef?.current;
      map.on("load", () => {
        setMapLoading(false);
        // Add markers for each coordinate if provided
        if (settings.waypoints) {
          // const lastIdx = settings.waypoints.length - 1;
          settings.waypoints.forEach((wp, idx) => {
            const isFeedLocation = settings?.feedLocations?.some(
              (fl) => fl.waypoint_id === wp.waypoint_id
            );

            let markerElement = document.createElement("span");
            if (idx === 0) {
              markerElement.className =
                "text-smooth-primary !text-4xl material-symbols-rounded";
              markerElement.textContent = "home_pin";
            }
            // } else if (idx === lastIdx) {
            //   markerElement.className =
            //     "text-smooth-primary !text-4xl material-symbols-rounded";
            //   markerElement.textContent = "flag_circle";
            // } else {
            else {
              markerElement.className = `waypoint-marker${
                isFeedLocation ? " waypoint-marker--feed" : ""
              }`;
              markerElement.addEventListener("click", () => {
                if (onClickHandler)
                  onClickHandler(wp.waypoint_id, [wp.lng, wp.lat]);
              });
            }

            new Marker(markerElement).setLngLat([wp.lng, wp.lat]).addTo(map);
          });

          // Add a line connecting all waypoints
          const lineCoordinates = settings.waypoints.map((wp) => [
            wp.lng,
            wp.lat,
          ]);
          map.addSource("route", {
            type: "geojson",
            data: {
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: lineCoordinates,
              },
            },
          });

          map.addLayer({
            id: "route",
            type: "line",
            source: "route",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#458621",
              "line-width": 6,
              "line-opacity": 0.35,
            },
          });
        }
      });
    } catch (e) {
      console.error(e);
      setError("An error occurred while loading the map.");
    }
  }, [mapSettings, onClickHandler]);

  useEffect(() => {
    if (!mapRef.current) {
      console.log("No map ref init map");
      setMapLoading(false);
      initializeMap();
    }

    return () => {
      if (mapRef.current) {
        console.log("map ref and use effect return");

        // mapRef.current?.off("load", initializeMap);
      }
    };
  }, [initializeMap]);

  return {
    map: mapRef.current,
    mapContainer: mapContainerRef,
    error,
    mapLoading,
  };
};
