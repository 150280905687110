import { reaction, flow, observable, action, makeObservable } from "mobx";
import { Entity, EntityType } from "../../models/common";
import { IEquipment } from "../../models/rover";
import BaseStore from "./BaseStore";
import RanchStore from "./RanchStore";
import api from "../../api";
import { EquipmentResponse } from "../../api/equipment";

class EquipmentStore extends BaseStore<IEquipment> {
  ranchStore: RanchStore;
  // store a map of all equipment keyed by entity type
  equipmentMap = observable.map<EntityType, IEquipment[]>();

  constructor(ranchStore: RanchStore) {
    super();
    this.ranchStore = ranchStore;
    makeObservable(this, {
      equipmentMap: observable,
      fetchEquipmentByRanch: flow,
      // associateEquipmentWithUser: flow,
      processEquipment: action,
      updateEquipmentMapByType: action,
      updateSingleEquipment: action,
      getEquipmentByType: action,
      getEquipmentByEntityId: action,
      getEquipmentByEntity: action,
      buildEntity: action,
      reset: action,
    });

    // Auto-fetch equipment when the ranch entity changes, if needed
    reaction(
      () => this.ranchStore.getEntity,
      (ranch) => {
        if (ranch && ranch.ranch_id) {
          this.fetchEquipmentByRanch(ranch.ranch_id);
        } else {
          this.reset(); // Reset equipment if no ranch is selected
        }
      }
    );
  }

  reset(): void {
    this.setLoading(false);
    this.setError(null);
    this.setEntity(null);
    this.equipmentMap.clear();
  }

  *fetchEquipmentByRanch(ranchId: string) {
    this.setLoading(true);
    this.setError(null);
    try {
      const { data }: EquipmentResponse = yield api.getEquipmentByRanch(
        ranchId
      );
      if (data && data.equipment) {
        // Process and organize equipment by type
        this.processEquipment(data.equipment);
      }
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  buildEntity = (entityID: string): Entity | undefined => {
    const eq = this.getEquipmentByEntityId(entityID);
    if (eq) {
      return {
        entity_id: entityID,
        entity_type: eq.entity_type,
      };
    }
  };

  processEquipment = (equipment: IEquipment | IEquipment[]) => {
    if (Array.isArray(equipment)) {
      this.equipmentMap.clear();
      equipment.forEach((eq) => {
        const existing = this.equipmentMap.get(eq.entity_type) || [];
        this.equipmentMap.set(eq.entity_type, [...existing, eq]);
      });
    }
  };

  updateEquipmentMapByType = (type: EntityType, equipment: IEquipment[]) => {
    this.equipmentMap.set(type, equipment);
  };

  getEquipmentByType = (type: EntityType): IEquipment[] => {
    return this.equipmentMap.get(type) || [];
  };

  getEquipmentByEntityId = (entityID: string): IEquipment | undefined => {
    let foundEquipment: IEquipment | undefined;

    this.equipmentMap.forEach((equipmentList) => {
      const equipment = equipmentList.find((eq) => eq.entity_id === entityID);
      if (equipment) {
        foundEquipment = equipment;
        return;
      }
    });

    return foundEquipment;
  };

  getEquipmentByEntity = (entity: Entity): IEquipment | undefined => {
    const equipmentMap = this.equipmentMap.get(entity.entity_type);
    const equipment = equipmentMap?.find((eq) => {
      return eq.entity_id === entity.entity_id;
    });

    return equipment;
  };

  updateSingleEquipment = (updatedEquipment: IEquipment) => {
    const equipmentTypeList = this.equipmentMap.get(
      updatedEquipment.entity_type
    );
    if (equipmentTypeList) {
      const index = equipmentTypeList.findIndex(
        (eq) => eq.entity_id === updatedEquipment.entity_id
      );
      if (index !== -1) {
        equipmentTypeList[index] = updatedEquipment; // Update in place
        this.equipmentMap.set(updatedEquipment.entity_type, [
          ...equipmentTypeList,
        ]);
      }
    }
  };
}

export default EquipmentStore;
