import React, { useCallback, useEffect, useState } from "react";
import { AddressAutofill } from "@mapbox/search-js-react";
import TextInput from "../TextInput";
import FormControl from "../../FormControl";
import classNames from "classnames";
// import { Feature } from "../../../models/common";
import { IAddress } from "../../../models/address";
import { useFormContext } from "react-hook-form";
import { Feature } from "../../../models/map";
import { LocationMap } from "../../MapBox";
import { Button } from "../../Button";

const AddressAutofillAny = AddressAutofill as any;
const AddressInput = ({
  defaultAddress,
}: {
  defaultAddress?: Partial<IAddress>;
}) => {
  const token = String(process.env.REACT_APP_MAPBOX_ACCESS_TOKEN);
  const [expanded, setExpanded] = useState(
    typeof defaultAddress !== "undefined"
  );
  const { setValue, watch } = useFormContext();
  const [showAutofill, setShowAutofill] = useState(
    expanded || defaultAddress?.line1 !== ""
  );
  const [feature, setFeature] = useState<Feature>();
  const handleRetrieve = useCallback(
    (res: { features: Feature[] }) => {
      const feature = res.features[0];
      setFeature(feature);
      setExpanded(true);
    },
    [setFeature]
  );
  const [mapCenter, setMapCenter] = useState({
    lng: defaultAddress?.lng,
    lat: defaultAddress?.lat,
  });

  const handleMapCenterChange = (lng: number, lat: number) => {
    setMapCenter({ lng, lat });
  };

  useEffect(() => {
    const coordinates = feature?.geometry?.coordinates;
    if (coordinates) {
      setValue("address.lng", coordinates[0]);
      setValue("address.lat", coordinates[1]);
      handleMapCenterChange(coordinates[0], coordinates[1]);
    }
  }, [feature, setValue]);

  // Function to handle address selection from LocationMap
  const handleAddressSelect = useCallback(
    (address: Partial<IAddress>) => {
      setValue("address.line1", `${address.line1}`);
      setValue("address.city", address.city);
      setValue("address.state", address.state);
      setValue("address.zip", address.zip);
      setValue("address.country", address.country);
      setValue("address.lng", address.lng);
      setValue("address.lat", address.lat);

      setExpanded(true);
    },
    [setValue]
  );

  return (
    <>
      <div className="block">
        <LocationMap
          mapCenter={mapCenter}
          onAddressSelect={handleAddressSelect}
        />
        {!showAutofill ? (
          <Button
            className="btn-link text-sm font-normal text-smooth-primary-40 px-0"
            onClickHandler={() => setShowAutofill(true)}
          >
            Enter address manually
          </Button>
        ) : null}
      </div>
      {showAutofill ? (
        <div className="pt-6">
          <AddressAutofillAny accessToken={token} onRetrieve={handleRetrieve}>
            <FormControl>
              <TextInput
                id="mapbox-autofill"
                name="address.line1"
                label="Address"
                placeholder="Start typing your address"
                autoComplete="address-line1"
                helperText="Begin typing address to autofill"
                defaultValue={defaultAddress && defaultAddress.line1}
              />
            </FormControl>
          </AddressAutofillAny>
        </div>
      ) : null}
      <div
        className={classNames(
          "grid gap-4",
          expanded ? "mt-4 visible h-full" : "invisible !h-0"
        )}
      >
        {watch("address.line2") !== "" ? (
          <FormControl>
            <TextInput
              name="address.line2"
              label="Address Line 2"
              placeholder="Apartment, suite, unit, building, floor, etc."
              autoComplete="address-line2"
              defaultValue={defaultAddress && defaultAddress.line2}
            />
          </FormControl>
        ) : null}
        <FormControl>
          <TextInput
            name="address.city"
            label="City"
            autoComplete="address-level2"
            rules={{
              required: {
                value: true,
                message: "Must not be empty",
              },
            }}
            defaultValue={defaultAddress && defaultAddress.city}
          />
        </FormControl>
        <FormControl>
          <TextInput
            name="address.state"
            label="State"
            autoComplete="address-level1"
            rules={{
              required: {
                value: true,
                message: "Must not be empty",
              },
            }}
            defaultValue={defaultAddress && defaultAddress.state}
          />
        </FormControl>
        <FormControl className="hidden">
          <TextInput
            name="address.country"
            label="Country"
            autoComplete="country"
            hidden
            defaultValue={defaultAddress && defaultAddress.country}
          />
        </FormControl>
        <div className="hidden">
          <FormControl>
            <TextInput label="lng" name="address.lng" hidden />
          </FormControl>
          <FormControl>
            <TextInput label="lat" name="address.lat" hidden />
          </FormControl>
        </div>
        <FormControl>
          <TextInput
            name="address.zip"
            label="Zip/Postal Code"
            autoComplete="postal-code"
            rules={{
              required: {
                value: true,
                message: "Must not be empty",
              },
            }}
            defaultValue={defaultAddress && defaultAddress.zip}
          />
        </FormControl>
      </div>
    </>
  );
};

export default AddressInput;
