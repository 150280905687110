import { Navigate, Route, Routes } from "react-router-dom";
import { observer } from "mobx-react";
import { routes } from "../routes";
import { Loading } from "../components";
import { useStores } from "../context";

const MainRouter = observer(() => {
  const { authStore } = useStores();
  if (authStore.isLoading) return <Loading />;

  // basically a user is logged in, but hasn't completed setup
  // did this so that users cant access dashboard and other views
  // without some basic data like ranch
  const isPartiallyAuthenticated =
    authStore.getIsAuthenticated && !authStore.getEntity?.completed_setup;

  return (
    <Routes>
      {routes.map(({ path, element: Component, private: isPrivate }, key) => {
        // this is ugly and needs a better solution
        // but im not gonna be the one to do it
        const elementAccess =
          !isPrivate ||
          (authStore.getIsAuthenticated &&
            (authStore.getEntity?.completed_setup ||
              path === "/welcome" ||
              path === "/user-account")) ? (
            <Component />
          ) : (
            <Navigate
              to={isPartiallyAuthenticated ? "/welcome" : "/login"}
              replace
            />
          );

        return <Route key={key} path={path} element={elementAccess} />;
      })}
    </Routes>
  );
});

export default MainRouter;
