import classNames from "classnames";
import React, { ReactNode } from "react";
import { Button } from "../Button";
import { ArrowBack } from "../../Icons";
import Text from "../Text";
import { TextVariant } from "../../utils/const";

interface SideSheetProps {
  onClose: () => void;
  isOpen: boolean;
  children: ReactNode;
}

const SideSheet = ({ onClose, isOpen, children }: SideSheetProps) => {
  const sideSheetClasses = classNames("bg-white py-4");

  return (
    <div className={sideSheetClasses}>
      {/* <Button className="btn-ghost" onClickHandler={onClose}>
        <ArrowBack />
        <Text
          variant={TextVariant.TitleLarge}
          className="font-normal text-smooth-neutral-variant-40"
        >
          Back
        </Text>
      </Button> */}
      {children}
    </div>
  );
};

export default SideSheet;
