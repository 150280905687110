import React, { ReactNode } from "react";
import classNames from "classnames";
import { TextVariant } from "../../utils/const";
import Text from "../Text";

interface SectionTitleProps {
  title: string;
  subTitle?: string;
  variant?: "primary" | "secondary";
  action?: {
    title: string;
    handler: () => void;
  };
  className?: string;
  children?: ReactNode;
}

const SectionTitle = ({
  title,
  subTitle,
  variant = "primary",
  action,
  className,
  children,
}: SectionTitleProps) => {
  const sectionClasses = classNames("w-full pb-4", className);
  const textVariant = variant === "primary" ? TextVariant.H1 : TextVariant.H5;
  return (
    <>
      <div className={sectionClasses}>
        <div className="flex justify-between items-center">
          <Text variant={textVariant}>{title}</Text>
          {subTitle && <Text variant={TextVariant.H5}>{subTitle}</Text>}
          {action && (
            <button
              className="btn btn-sm btn-link normal-case font-normal no-underline"
              onClick={action?.handler}
            >
              {action?.title}
            </button>
          )}
        </div>
        <div className="block w-full">{children}</div>
      </div>
    </>
  );
};

export default SectionTitle;
