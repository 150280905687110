import { observable, action, computed, flow, makeObservable } from "mobx";
import { IUser, UserRegistration } from "../../models/user";
import api from "../../api";
import {
  decodeToken,
  getStorageToken,
  removeStorageToken,
  setStorageToken,
} from "../../utils/auth";
import BaseStore from "./BaseStore";

/**
 * AuthStore manages the authentication state and operations of the application.
 * It handles user login, logout, and token management.
 */
class AuthStore extends BaseStore<IUser> {
  token: string | null = null;
  authenticated = false;

  constructor() {
    super(); // Call BaseStore constructor
    makeObservable(this, {
      token: observable,
      authenticated: observable,
      setAuthenticated: action,
      reset: action,
      setToken: action,
      getToken: computed,
      getUserID: computed,
      register: flow,
      login: flow,
      logout: flow,
      initializeFromLocalStorage: flow,
      getIsAuthenticated: computed,
      completeUserSetup: flow,
    });
    this.initializeFromLocalStorage();
  }

  /**
   * Sets the authenticated state.
   * @param {boolean} authenticated - The authenticated state to set.
   */
  setAuthenticated(authenticated: boolean): void {
    this.authenticated = authenticated;
  }

  /**
   * Sets the authentication token.
   * @param {string | null} token - The token to set.
   */
  setToken(token: string | null): void {
    this.token = token;
    if (token) {
      setStorageToken(token);
    }
  }

  /**
   * Initializes the store from local storage.
   * It retrieves the token and fetches user data if the token is valid.
   */
  *initializeFromLocalStorage() {
    this.setLoading(true);
    try {
      const token = getStorageToken();
      if (token && !this.getEntity) {
        this.setToken(token);

        const decoded = decodeToken(token);
        if (decoded) {
          const { data } = yield api.getUserByID(decoded?.user_id);
          if (data?.user) {
            this.setEntity(data?.user);
            this.setAuthenticated(true);
          }
        }
      }
    } catch (error) {
      this.handleError(error);
      this.reset();
    } finally {
      this.setLoading(false);
    }
  }

  /**
   * Resets the store to its initial state.
   */
  reset() {
    this.setLoading(false);
    this.setEntity(null);
    this.setToken(null);
    this.setAuthenticated(false);
    removeStorageToken();
  }

  /**
   * Performs user login.
   * @param {string} email - User's email.
   * @param {string} password - User's password.
   */
  *register(user: UserRegistration) {
    this.setLoading(true);
    try {
      const { data } = yield api.registerUser({
        user: {
          ...user,
          email: user.email.toLowerCase(), // lowercase the email
        },
      });
      if (data?.user && data?.token) {
        this.setEntity(data.user);
        this.setToken(data.token);
        this.setAuthenticated(true);
      }
    } catch (error) {
      this.handleError(error);
      this.setAuthenticated(false);
    } finally {
      this.setLoading(false);
    }
  }

  /**
   * Performs user login.
   * @param {string} email - User's email.
   * @param {string} password - User's password.
   */
  *login(email: string, password: string, onSuccess?: () => void) {
    this.setLoading(true);
    try {
      const { data } = yield api.loginUser({
        email: email.toLowerCase(), // lowercase the email
        password,
      });

      if (data?.user && data?.token) {
        this.setEntity(data.user);
        this.setToken(data.token);
        this.setAuthenticated(true);
        if (onSuccess) {
          onSuccess(); // Call the onSuccess callback after successful login
        }
      }
    } catch (error) {
      this.handleError(error);
      this.setAuthenticated(false);
    } finally {
      this.setLoading(false);
    }
  }

  *completeUserSetup() {
    this.setLoading(true);
    this.setError(null);
    try {
      const user = this.getEntity;
      if (user) {
        const { data } = yield api.completeUserSetup(user?.user_id);

        if (data?.user) {
          this.setEntity(data.user);
        }
      }
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  /**
   * Logs out the current user.
   */
  *logout() {
    this.setLoading(true);
    try {
      const token = this.token;
      if (token) {
        yield api.logoutUser({ token });
      }
    } catch (error) {
      this.handleError(error);
    } finally {
      this.reset();
    }
  }

  /**
   * Returns whether the user is authenticated.
   */
  get getIsAuthenticated(): boolean {
    return this.authenticated;
  }

  get getToken(): string | null {
    return this.token;
  }

  get getUserID() {
    return this.getEntity?.user_id;
  }
}

export default AuthStore;
