import React, { useState, FC, useEffect } from "react";
import ActionList from "../ActionMenu/ActionList";
import { ActionListItem } from "../ActionMenu";
import { Option, OptionValue } from "../../models/common";
import { ListButton } from "../Button";
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";
import { FormControl, FormHelperText, InputLabel } from "@mui/material";
import { useBottomSheet } from "../../context";
import Text from "../Text";
import { TextVariant } from "../../utils/const";

interface SelectionListProps extends UseControllerProps {
  id?: string;
  name: string;
  label?: string;
  options: Option[];
  helperText?: string;
  placeholder?: string;
  defaultValue?: OptionValue;
  onItemSelected?: (value: OptionValue) => void;
}

const SelectionContentWrapper = ({
  defaultSelected,
  options,
  onSelect,
}: {
  options: Option[];
  onSelect: (value: OptionValue) => void;
  defaultSelected: OptionValue;
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultSelected);
  const handleOnSelect = (value: any) => {
    setSelectedValue(value);
    onSelect(value);
  };

  return (
    <ActionList className="">
      {options.map(({ value, label }) => (
        <ActionListItem key={value}>
          <ListButton
            onClickHandler={() => handleOnSelect(value)}
            className={`justify-between ${
              value === selectedValue
                ? "bg-smooth-secondary-90 border-smooth-primary"
                : ""
            }`}
          >
            {label}
            <input
              type="radio"
              name="selection"
              checked={value === selectedValue}
              onChange={() => onSelect(value)}
              className="radio radio-primary"
            />
          </ListButton>
        </ActionListItem>
      ))}
    </ActionList>
  );
};

const SelectionList: FC<SelectionListProps> = ({
  id,
  label,
  name,
  rules,
  options,
  defaultValue,
  onItemSelected,
  helperText,
  placeholder,
  ...controllerProps
}) => {
  const { control, setValue } = useFormContext();
  const { showSideSheet, hideSideSheet } = useBottomSheet();

  const handleSelect = (value: OptionValue) => {
    setValue(name, value, { shouldValidate: true });
    if (onItemSelected) onItemSelected(value);
    hideSideSheet();
  };

  return (
    <>
      <Controller
        {...controllerProps}
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field, fieldState: { error } }) => {
          const handleOpenSideSheet = () => {
            const content = (
              <SelectionContentWrapper
                options={options}
                onSelect={handleSelect}
                defaultSelected={field.value}
              />
            );

            showSideSheet(content);
          };
          const optionValue =
            options.find((option) => option.value === field.value)?.label ||
            field.value;
          return (
            <FormControl fullWidth error={!!error}>
              <ListButton
                className="relative"
                onClickHandler={handleOpenSideSheet}
              >
                {/* {placeholder && !field.value
                  ? placeholder
                  : options.find((option) => option.value === field.value)
                      ?.label || field.value} */}
                {!field.value ? (
                  <InputLabel htmlFor={id}>{label}</InputLabel>
                ) : (
                  <Text variant={TextVariant.BodyLarge}>{optionValue}</Text>
                )}
                <div className="flex items-center absolute top-1/2 transform right-0 -translate-y-1/2 px-4">
                  <span className="material-symbols-rounded">arrow_right</span>
                </div>
              </ListButton>
              <FormHelperText>
                {error ? error.message : helperText}
              </FormHelperText>
            </FormControl>
          );
        }}
      />
    </>
  );
};

export default SelectionList;
