import { action, flow, makeObservable, reaction } from "mobx";
import { IRanch } from "../../models/ranch";
import api from "../../api";
import { RanchResponse } from "../../api/ranch";
import BaseStore from "./BaseStore";
import AuthStore from "./AuthStore";
import { IUser } from "../../models/user";
import { ioClient } from "../../socket";

/**
 * RanchStore manages the state and operations related to ranches in the application.
 * It handles the retrieval, updating, and tracking of ranch information.
 */
class RanchStore extends BaseStore<IRanch> {
  authStore: AuthStore;

  constructor(authStore: AuthStore) {
    super();
    this.authStore = authStore;
    makeObservable(this, {
      fetchRanch: flow,
      addRanch: flow,
      updateRanch: flow,
      deleteRanch: flow,
      reset: action,
    });

    // this ensures that anytime the user in the auth state changes
    // we either fetch the ranch for that user, or reset state
    reaction(
      () => this.authStore.getEntity,
      (user) => {
        if (user) {
          this.fetchRanch(user);
        } else {
          this.reset();
        }
      }
    );
  }

  /**
   * Resets the store to its initial state.
   */
  reset(): void {
    this.setLoading(false);
    this.setError(null);
    this.setEntity(null);
    // make sure to clear the room id
    ioClient.clearRoomId();
  }

  /**
   * Fetches ranch data for a specific user ID and updates the store. */
  *fetchRanch(user: IUser) {
    // start loading, and setting fetching entity
    this.setLoading(true);
    this.setFetchingEntity(true);
    this.setError(null); // set errors to null

    try {
      // adding this so that we only fetch a ranch if we have a user
      if (user) {
        const { data }: RanchResponse = yield api.getRanchByUserID(
          user.user_id
        );

        if (data && data.ranch) {
          this.setEntity(data.ranch);
          // use ranch Id as the room
          ioClient.setRoomId(data.ranch.ranch_id);
        }
      }
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
      this.setFetchingEntity(false);
    }
  }

  /**
   * Adds a new ranch.
   * @param {Partial<IRanch>} newRanch - The data for the new ranch.
   */
  *addRanch(newRanch: Partial<IRanch>) {
    this.setLoading(true);
    this.setError(null);

    // TODO: Add validation before making request

    try {
      const { data }: RanchResponse = yield api.createRanch(newRanch);
      if (data && data.ranch) {
        const { ranch } = data;
        this.setEntity(ranch);
        // use ranch Id as the room
        ioClient.setRoomId(ranch.ranch_id);

        // now we associate the ranch with the user
        const user = this.authStore.getEntity;
        if (user) {
          yield api.addUserToRanch(ranch?.ranch_id, user?.user_id);
        }
      }
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  /**
   * Updates an existing ranch.
   * @param {string} ranchId - The ID of the ranch to update.
   * @param {Partial<IRanch>} updatedRanch - The updated ranch data.
   */
  *updateRanch(ranchId: string, updatedRanch: Partial<IRanch>) {
    this.setLoading(true);
    this.setError(null);

    // TODO: Add validation before making request
    try {
      const { data } = yield api.updateRanch(ranchId, updatedRanch);
      if (data && data.ranch) {
        this.setEntity(data.ranch);
      }
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  /**
   * Deletes a ranch.
   * @param {string} ranchId - The ID of the ranch to delete.
   */
  *deleteRanch(ranchId: string) {
    this.setLoading(true);
    try {
      yield api.deleteRanch(ranchId);
      this.setEntity(null);
    } catch (error) {
      this.handleError(error);
    } finally {
      this.reset();
    }
  }
}

export default RanchStore;
