import { IMission } from "../models/rover";
import { Axios } from "./core";

export type MissionResponse = {
  data: { mission?: IMission; missions?: IMission[]; message?: string };
};

export const createMissionSchedule = async (
  payload: Partial<IMission>
): Promise<MissionResponse> =>
  await Axios.post("/missions/add-mission-schedule", payload);

export const deleteMissionSchedule = async (
  missionID: string
): Promise<MissionResponse> =>
  await Axios.delete(`/missions/delete-mission-schedule/${missionID}`);

export const updateMission = async (
  missionID: string,
  payload: Partial<IMission>
): Promise<MissionResponse> =>
  await Axios.post(`/missions/update/${missionID}`, payload);

export const fetchMissionsByRover = async (
  roverId: string
): Promise<MissionResponse> =>
  await Axios.get(`/missions/get-by-rover/${roverId}`);
