import { useState, useEffect } from "react";
import { useSpring } from "react-spring";
import useMeasure from "react-use-measure";

export const useSheetAnimation = () => {
  const [showSideSheet, setShowSideSheet] = useState(false);
  const [contentRef, contentBounds] = useMeasure({ polyfill: ResizeObserver });
  const [sideSheetRef, sideSheetBounds] = useMeasure({
    polyfill: ResizeObserver,
  });

  // Height transition animation
  const [height, setHeight] = useState(contentBounds.height);

  useEffect(() => {
    setHeight(showSideSheet ? sideSheetBounds.height : contentBounds.height);
  }, [showSideSheet, contentBounds.height, sideSheetBounds.height]);

  const heightAnimation = useSpring({
    height,
    config: {
      tension: 200,
      friction: 25,
    },
  });

  const slideAnimation = useSpring({
    transform: showSideSheet ? "translateX(-100%)" : "translateX(0%)",
    config: {
      tension: 200,
      friction: 25,
    },
  });

  const sideSheetAnimation = useSpring({
    transform: showSideSheet ? "translateX(0%)" : "translateX(100%)",
    config: {
      tension: 200,
      friction: 25,
    },
  });

  const toggleSideSheet = (toShow: boolean) => setShowSideSheet(toShow);

  return {
    heightAnimation,
    slideAnimation,
    sideSheetAnimation,
    toggleSideSheet,
    contentRef,
    sideSheetRef,
    showSideSheet,
  };
};
