import {
  LinearProgress as BaseLinearProgress,
  linearProgressClasses,
  styled,
} from "@mui/material";

export const LinearProgress = styled(BaseLinearProgress)(() => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E0E4D6",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#458621",
  },
}));
