import classnames from "classnames";
import React, { ReactNode } from "react";

interface CardProps {
  className?: string;
  children: ReactNode;
  onClickHandler?: () => void;
}

const Card = ({ className, children, onClickHandler }: CardProps) => {
  const cardClasses = classnames("card bg-smooth-primary-100", className);
  return (
    <div className={cardClasses} onClick={onClickHandler}>
      <div className="card-body p-0">{children}</div>
    </div>
  );
};

export default Card;
