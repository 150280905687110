import { TrashCan } from "../../Icons";
import Button from "./Button";

interface DeleteButtonProps {
  onClickHandler: () => void;
  label?: string;
}

const DeleteButton = ({
  onClickHandler,
  label = "Delete",
}: DeleteButtonProps) => {
  return (
    <Button
      type="button"
      onClickHandler={onClickHandler}
      className="btn-ghost border-smooth-error-50 font-medium text-smooth-error-40 w-full active:bg-smooth-error-95 focus:bg-smooth-error-95"
    >
      <TrashCan />
      {label}
    </Button>
  );
};

export default DeleteButton;
