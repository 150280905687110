import React from "react";
import { ICattle } from "../../models/cattle";
import Card from "./Card";
import Text from "../Text";
import { TextVariant } from "../../utils/const";
import { ArrowRight, NearMe } from "../../Icons";
import Badge from "../Badge";
import { SecondaryButton } from "../Button";
import { useNavigate } from "../../hooks/useNavigate";

interface CattleCardProp {
  cattle: ICattle;
}

const CattleCard = ({ cattle }: CattleCardProp) => {
  const navigate = useNavigate();
  const { cattle_id, data } = cattle;
  const { tag, gender, weight, age, health_status } = data;

  const handleOnClick = () => {
    navigate(`/cattle/${cattle?.cattle_id}`, { state: cattle });
  };

  return (
    <Card className="rounded-lg mb-4">
      <div className="flex flex-col" onClick={handleOnClick}>
        <div className="flex justify-between items-center">
          <Text variant={TextVariant.BodyLarge}>{tag}</Text>
          <ArrowRight />
        </div>
        <Text
          variant={TextVariant.Paragraph}
          className="text-smooth-neutral-variant"
        >
          {gender} / {weight}lbs / {age}
        </Text>
        <div className="self-start mt-4">
          {health_status && <Badge label={health_status} />}
        </div>
      </div>
      <div className="card-actions pt-4">
        <SecondaryButton>
          <NearMe />
          Locate
        </SecondaryButton>
      </div>
    </Card>
  );
};

export default CattleCard;
