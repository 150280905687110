import classNames from "classnames";
import React, { ReactNode } from "react";

const ActionListItem = ({
  className,
  children,
}: {
  children: ReactNode;
  className?: string;
}) => {
  const styles = classNames("block", className);
  return <li className={styles}>{children}</li>;
};

export default ActionListItem;
