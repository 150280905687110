import { FeedScheduleModel } from "../models/feed-schedule";
import { Axios } from "./core";

export const getFeedScheduleByID = async (
  id: number
): Promise<{ data: { schedule: FeedScheduleModel } }> =>
  await Axios.get(`/feed-schedules/${id}`);

export const getFeedSchedulesByFeederID = async (
  feederID: number
): Promise<{ data: { schedules: FeedScheduleModel[] } }> =>
  await Axios.get(`/feed-schedules/feeder/${feederID}`);

export const createFeedSchedule = async (
  payload: Partial<FeedScheduleModel>
): Promise<{ data: FeedScheduleModel }> =>
  await Axios.post("/feed-schedules/create", payload);

export const updateFeedSchedule = async (
  id: number,
  payload: Partial<FeedScheduleModel>
): Promise<{ data: FeedScheduleModel }> =>
  await Axios.put(`/feed-schedules/update/${id}`, payload);

export const deleteFeedSchedule = async (id: number): Promise<void> =>
  await Axios.delete(`/feed-schedules/delete/${id}`);
