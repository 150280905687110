import React, { useCallback, useEffect, useRef, useState } from "react";
import { useMap } from "../../hooks";
import { FullscreenControl, Marker, NavigationControl } from "mapbox-gl";
import { Feature, MapSettings } from "../../models/map";
import { IAddress } from "../../models/address";
import { STATES_MAP } from "../../utils/const";

// Define default settings
const defaultSettings: MapSettings = {
  center: {
    lng: -98.5795,
    lat: 39.8283,
  },
  zoom: 3,
  style: "mapbox://styles/hxlloghxst/clsuxnrn3006i01p42o6res1o",
};

interface LocationMapProps {
  mapCenter: {
    lng?: number;
    lat?: number;
  };
  onAddressSelect?: (address: Partial<IAddress>) => void;
}

const LocationMap = ({ mapCenter, onAddressSelect }: LocationMapProps) => {
  const { map, mapContainer } = useMap(defaultSettings);
  const [lng, setLng] = useState<number>();
  const [lat, setLat] = useState<number>();
  const [zoom, setZoom] = useState(defaultSettings.zoom);
  const markerRef = useRef<Marker>();

  // Function to perform reverse geocoding
  const reverseGeoCode = useCallback(
    async ({
      longitude,
      latitude,
    }: {
      longitude: number;
      latitude: number;
    }) => {
      const types = "country,region,postcode,place,address"; // Focus on relevant types
      const token = String(process.env.REACT_APP_MAPBOX_ACCESS_TOKEN);
      const url =
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?` +
        `access_token=${token}&types=${types}`;

      try {
        const response = await fetch(url);
        const data = await response.json();

        // Initialize an object to hold your formatted address
        const address = {
          line1: "",
          city: "",
          state: "",
          zip: "",
          country: "",
          lng: longitude,
          lat: latitude,
        };

        // Iterate through the features to extract the required information
        data.features.forEach((feature: Feature) => {
          // Use place_type to determine the type of each feature
          const type = feature.place_type[0];
          switch (type) {
            case "address":
              const line = feature.place_name.split(",")[0];
              address.line1 = line;
              break;
            case "place":
              address.city = feature.text;
              break;
            case "region":
              // map to short hand: Texas -> TX
              address.state = STATES_MAP[feature.text];
              break;
            case "postcode":
              address.zip = feature.text;
              break;
            case "country":
              address.country = feature.short_code || "us";
              break;
            default:
              break;
          }
        });

        // if we have a callback, call that shit back
        if (onAddressSelect) {
          onAddressSelect(address);
        }

        return address;
      } catch (error) {
        console.error("Error fetching address:", error);
      }
    },
    [onAddressSelect]
  );

  useEffect(() => {
    // set the zoom level
    setZoom(14);
    // only use the geolocaton if default lng/lat is provided
    if (mapCenter.lng && mapCenter.lat) {
      setLng(mapCenter.lng);
      setLat(mapCenter.lat);
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLng(position.coords.longitude);
          setLat(position.coords.latitude);
        },
        (err) => console.log(err),
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    }
  }, [mapCenter.lat, mapCenter.lng]);

  // Add navigation control (zoom and rotation)
  useEffect(() => {
    const fullScreenControl = new FullscreenControl();
    const navigationControl = new NavigationControl();
    if (map) {
      map.addControl(navigationControl, "top-left");
      map.addControl(fullScreenControl, "top-right");

      if (lng && lat) {
        markerRef.current = new Marker().setLngLat([lng, lat]).addTo(map);
        const marker = markerRef.current;

        map.on("click", (e) => {
          marker.setLngLat(e.lngLat);
          // do this on click and on load
          reverseGeoCode({ longitude: e.lngLat.lng, latitude: e.lngLat.lat });
        });

        // map.on("load", () => {
        // });
        // Update map center and marker position when user's location is obtained
        map.flyTo({
          center: [lng, lat],
          zoom: zoom,
        });
        marker.setLngLat([lng, lat]);
        // do this on click and on load
        reverseGeoCode({ longitude: lng, latitude: lat });
      }

      if (map && mapCenter.lng && mapCenter.lat) {
        map.flyTo({
          center: [mapCenter.lng, mapCenter.lat],
          zoom: zoom,
        });
        markerRef.current?.setLngLat([mapCenter.lng, mapCenter.lat]);
      }
    }

    // Cleanup function
    return () => {
      map?.removeControl(navigationControl);
      map?.removeControl(fullScreenControl);
      markerRef.current?.remove();
    };
  }, [map, lng, lat, zoom, reverseGeoCode, mapCenter.lng, mapCenter.lat]);

  return (
    <div className="relative w-full">
      <div ref={mapContainer} className="map h-96 md:h-[500px] rounded-lg" />
    </div>
  );
};

export default LocationMap;
