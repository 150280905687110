import {
  action,
  computed,
  flow,
  makeObservable,
  observable,
  reaction,
} from "mobx";
import BaseStore from "./BaseStore";
import RanchStore from "./RanchStore";
import { RanchResponse } from "../../api/ranch";
import api from "../../api";
import { IFeedLocation, RouteWithFeedLocations } from "../../models/rover";
import { ioClient } from "../../socket";
import { RoutesResponse } from "../../api/routes";

class RouteStore extends BaseStore<RouteWithFeedLocations> {
  ranchStore: RanchStore;
  routes = new Map<string, RouteWithFeedLocations>();

  constructor(ranchStore: RanchStore) {
    super();
    this.ranchStore = ranchStore;

    makeObservable(this, {
      routes: observable,
      setRoutes: action,
      fetchRoutesByRanch: flow,
      updateRoverRoute: flow,
      getRoutes: computed,
      getRouteByID: action,
      setupSocketListeners: action,
      addRoute: action,
    });

    // do this to make sure we always get the routes for the ranch
    reaction(
      () => this.ranchStore.getEntity,
      (ranch) => {
        if (ranch && ranch.ranch_id) {
          this.fetchRoutesByRanch(ranch.ranch_id);
        } else {
          this.reset();
        }
      }
    );

    this.setupSocketListeners();
  }

  reset(): void {
    this.setLoading(false);
    this.setError(null);
    this.setEntity(null);
  }

  addRoute(route: RouteWithFeedLocations) {
    this.routes.set(route.route_id, route);
  }

  setupSocketListeners = () => {
    ioClient.onEvent<{ roverID: string; routes: RouteWithFeedLocations[] }>(
      "ROUTES_ADDED",
      ({ routes }) => {
        this.setRoutes(routes);
      }
    );
  };

  *fetchRoutesByRanch(ranchId: string) {
    this.setLoading(true);
    this.setFetchingEntity(true);
    this.setError(null);
    try {
      if (ranchId) {
        const { data }: RanchResponse = yield api.getRanchRoutes(ranchId);
        if (data && data.routes) {
          this.setRoutes(data.routes);
        }
      }
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
      this.setFetchingEntity(false);
    }
  }

  *updateRoverRoute(
    roverID: string,
    routeID: string,
    updatedRoute: Partial<RouteWithFeedLocations>
  ) {
    this.setLoading(true);
    this.setError(null);

    // TODO: Add validation before making request
    try {
      const { data }: RoutesResponse = yield api.updateRoverRoute(
        roverID,
        routeID,
        updatedRoute
      );
      if (data && data.route) {
        const route = {
          ...data.route,
          total_distance: Number(data.route.total_distance),
          estimated_time: Number(data.route.estimated_time),
          feedLocations: data.route.feedLocations?.map((loc) => ({
            ...loc,
            feed_amount: Number(loc.feed_amount),
          })) as Partial<IFeedLocation>[],
        };

        this.addRoute(route);
      }
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  setRoutes(routes: RouteWithFeedLocations[]) {
    this.routes.clear();
    const formattedRoutes = routes.map((route) => {
      return {
        ...route,
        total_distance: Number(route.total_distance),
        estimated_time: Number(route.estimated_time),
        feedLocations: route.feedLocations?.map((loc) => ({
          ...loc,
          feed_amount: Number(loc.feed_amount),
        })) as IFeedLocation[],
      };
    });
    formattedRoutes.forEach((route) => {
      this.addRoute(route);
    });
  }

  get getRoutes(): RouteWithFeedLocations[] {
    return Array.from(this.routes.values()) || [];
  }

  getRouteByID(routeId: string): RouteWithFeedLocations | undefined {
    const route = this.routes.get(routeId);
    if (route) {
      this.setEntity(route);
    }
    return route;
  }
}

export default RouteStore;
