import axios from "axios";
import { getStorageToken } from "../utils/auth";

const Axios = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  timeout: 1000,
});

Axios.interceptors.request.use(
  (config) => {
    const token = getStorageToken();

    // set the auth token in the header of each request
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`; // set token to the authorization header
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// You can also add a response interceptor if needed
Axios.interceptors.response.use(
  (response) => {
    // Do something with the response data
    return response;
  },
  (error) => {
    // Do something with the response error
    return Promise.reject(error);
  }
);

export { Axios };
