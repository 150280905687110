import React, { ReactNode } from "react";
import { Transition } from "react-transition-group";

type SideDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
};

const duration = 150;

const defaultStyle = {
  transition: `transform ${duration}ms ease-in-out`,
  transform: "translateX(-100%)",
};

const transitionStyles: { [id: string]: React.CSSProperties } = {
  entering: { transform: "translateX(-100%)" },
  entered: { transform: "translateX(0%)" },
  exiting: { transform: "translateX(0%)" },
  exited: { transform: "translateX(-100%)" },
};

const SideDrawer = ({ isOpen, onClose, children }: SideDrawerProps) => {
  return (
    <Transition in={isOpen} timeout={duration} unmountOnExit>
      {(state) => (
        <div
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
          className="fixed inset-y-0 left-0 z-50 w-80 overflow-hidden bg-base-100 shadow-lg"
        >
          <label
            htmlFor="my-drawer"
            className="drawer-overlay"
            onClick={onClose}
          ></label>
          <ul className="menu p-4 overflow-y-auto w-full">
            {/* Drawer content */}
            {children}
          </ul>
        </div>
      )}
    </Transition>
  );
};

export default SideDrawer;
