import React, { CSSProperties } from "react";
import { TextVariant } from "../../utils/const";
import Text from "../Text";
import Card from "./Card";
import { SecondaryButton } from "../Button";
import { PastureData } from "../../models/pasture";
import Badge from "../Badge";
import { WeatherVitals } from "../Vitals";

interface PastureCardProps {
  pasture: PastureData;
}

const PastureCard = ({ pasture }: PastureCardProps) => {
  const { name, total_area: area, status } = pasture;
  return (
    <Card className="w-80">
      <div className="grid gap-4">
        {/* Row 1: Pasture name, sq ft, and Ready for cattle status */}
        <div className="flex justify-between items-center">
          <div>
            <Text variant={TextVariant.BodyLarge}>{name}</Text>
            <Text variant={TextVariant.Paragraph}>{area} sq ft</Text>
          </div>
          <Badge label={status} />
        </div>

        {/* Row 2: Weather details */}
        <WeatherVitals />

        {/* Row 3: Radial progress bars */}
        <div className="flex flex-row justify-between">
          <div
            className="radial-progress text-smooth-primary-60 bg-smooth-secondary-90/30"
            style={
              {
                "--value": 50 || 0,
                "--size": "3.5rem",
                "--thickness": "2.5px",
              } as CSSProperties
            }
          >
            <span className="text-xxs text-center text-smooth-primary">
              Feed
            </span>
            <span className="text-body-md text-smooth-primary">50%</span>
          </div>
          <div
            className="radial-progress text-smooth-primary-60 bg-smooth-secondary-90/30"
            style={
              {
                "--value": 25 || 0,
                "--size": "3.5rem",
                "--thickness": "2.5px",
              } as CSSProperties
            }
          >
            <span className="text-xxs text-center text-smooth-primary">
              Water
            </span>
            <span className="text-body-md text-smooth-primary">25%</span>
          </div>
          <div
            className="radial-progress text-smooth-primary-60 bg-smooth-secondary-90/30"
            style={
              {
                "--value": 80 || 0,
                "--size": "3.5rem",
                "--thickness": "2.5px",
              } as CSSProperties
            }
          >
            <span className="text-xxs text-center text-smooth-primary">
              Other
            </span>
            <span className="text-body-md text-smooth-primary">80%</span>
          </div>
          <div
            className="radial-progress text-smooth-primary-60 bg-smooth-secondary-90/30"
            style={
              {
                "--value": 75 || 0,
                "--size": "3.5rem",
                "--thickness": "2.5px",
              } as CSSProperties
            }
          >
            <span className="text-xxs text-center text-smooth-primary">
              Other
            </span>
            <span className="text-body-md text-smooth-primary">75%</span>
          </div>
        </div>

        {/* Row 4: View button */}
        <div className="card-actions">
          <SecondaryButton>View</SecondaryButton>
        </div>
      </div>
    </Card>
  );
};

export default PastureCard;
