import Button from "./Button";

interface EditButtonProps {
  onClickHandler: () => void;
  label?: string;
}

const EditButton = ({ onClickHandler, label = "Edit" }: EditButtonProps) => {
  return (
    <Button
      className="btn-ghost w-full border-smooth-primary-50 text-smooth-primary"
      type="button"
      onClickHandler={onClickHandler}
    >
      {label}
    </Button>
  );
};

export default EditButton;
