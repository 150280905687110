import { TextVariant } from "../../utils/const";
import Text from "../Text";
import Tile from "./Tile";

interface InfoTileProps {
  title: string;
  data: string;
}

const InfoTile = ({ title, data }: InfoTileProps) => {
  return (
    <Tile>
      <div>
        <Text variant={TextVariant.BodyLarge} className="text-center">
          {title}
        </Text>
        <Text
          variant={TextVariant.HeadlineMedium}
          className="text-center font-normal"
        >
          {data}
        </Text>
      </div>
    </Tile>
  );
};

export default InfoTile;
