import { Daytime } from "../models/schedule";

export const formatTime = (daytime: Daytime): string => {
  return `${daytime.time.hour}:${daytime.time.minute}${daytime.daytime}`;
};

export const formatDate = (date: Date) => {
  return new Date(date).toLocaleDateString("en", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  });
};

export const formatEstimatedTime = (timeInHours: number): string => {
  // Convert hours to total seconds and round up on the seconds
  const totalSeconds = Math.ceil(timeInHours * 3600);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  if (hours > 0) {
    return `${hours}h:${minutes.toString().padStart(2, "0")}m:${seconds
      .toString()
      .padStart(2, "0")}s`;
  } else {
    return `${minutes > 0 ? `${minutes}m ` : ""}${
      seconds > 0 || minutes === 0 ? `${seconds}s` : ""
    }`.trim();
  }
};

export const hoursToSeconds = (timeInHours?: number): number => {
  return timeInHours ? timeInHours * 3600 : 0;
};

export const formatTimeDaytime = (timeString: string): Daytime => {
  // Regular expression to match the time format "hh:mm am/pm"
  const timePattern = /^(\d{1,2}:\d{2})\s(am|pm)$/i;

  const matches = timeString.match(timePattern);

  if (matches) {
    // Extract the matched parts using destructuring
    const [, t, daytime] = matches;
    const time = t.split(":");
    return {
      time: {
        hour: time[0],
        minute: time[1],
      },
      daytime: daytime.toLowerCase(),
    };
  } else {
    // Return null if the input does not match the expected format
    console.error("Invalid time format. Please use 'hh:mm am/pm'.");
    return { time: { hour: "", minute: "" }, daytime: "am" };
  }
};

export const timePercent = (currentTime: number, totalTime: number): number => {
  return (currentTime / totalTime) * 100;
};
