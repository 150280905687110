import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useBottomSheet, useStores } from "../../context";
import { useMap } from "../../hooks";
import Text from "../Text";
import { TextVariant } from "../../utils";
import { FormControl, Skeleton } from "@mui/material";
import { IFeedLocation, RouteWithFeedLocations } from "../../models/rover";
import { TextInput } from "../Fields";
import { FormProvider, useForm } from "react-hook-form";
import ActionButtons from "../ActionButtons";

const FeedLocationsForm = ({
  waypoint,
  onAdd,
}: {
  waypoint: string;
  onAdd: (waypoint: string, feedAmount: number) => void;
}) => {
  const { hideSideSheet } = useBottomSheet();
  const methods = useForm({
    defaultValues: {
      feedAmount: null,
    },
  });
  const { handleSubmit } = methods;

  if (!waypoint) return null;

  const onSubmit = (data: { feedAmount: number | null }) => {
    if (data.feedAmount) onAdd(waypoint, data.feedAmount);
  };

  return (
    <>
      <Text variant={TextVariant.H2}>Add Feed Location</Text>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full mt-6 grid gap-4">
            <FormControl>
              <Text
                variant={TextVariant.TitleSmall}
                className="font-medium text-sm mb-2"
              >
                Feed Amount
              </Text>
              <TextInput
                name="feedAmount"
                label="Amount (lbs)"
                helperText="Amount to feed in lbs"
                rules={{
                  required: {
                    value: true,
                    message: "Must not be empty",
                  },
                }}
              />
            </FormControl>
            <FormControl>
              <ActionButtons
                saveLabel="Add Feed"
                onCancel={() => {
                  methods.reset();
                  hideSideSheet();
                }}
              />
            </FormControl>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

const FeedLocationsMap = ({
  route,
  setFeedLocations,
}: {
  route: RouteWithFeedLocations;
  setFeedLocations: Dispatch<SetStateAction<Partial<IFeedLocation>[]>>;
}) => {
  const { showSideSheet, hideSideSheet } = useBottomSheet();
  const { ranchStore } = useStores();
  const ranch = ranchStore.getEntity;

  const handleAddFeedLocation = (waypoint_id: string, feedAmount: number) => {
    if (waypoint_id) {
      setFeedLocations((locations) => [
        ...locations,
        {
          waypoint_id: waypoint_id,
          feed_amount: feedAmount,
        },
      ]);

      hideSideSheet();
    }
  };

  useEffect(() => {
    return hideSideSheet();
  }, [hideSideSheet]);

  const handleMarkerClick = (waypoint: string, lngLat: [number, number]) => {
    showSideSheet(
      <FeedLocationsForm waypoint={waypoint} onAdd={handleAddFeedLocation} />
    );
  };

  const { mapContainer, mapLoading } = useMap(
    {
      center: ranch?.address && { ...ranch?.address },
      zoom: 17,
      waypoints: route?.waypoints?.map((wp) => ({
        waypoint_id: wp.waypoint_id,
        lng: wp.lng,
        lat: wp.lat,
      })),
      feedLocations: route?.feedLocations?.map((fl) => ({
        waypoint_id: fl.waypoint_id,
        feedAmount: fl.feed_amount,
      })),
    },
    handleMarkerClick
  );

  // show loading if we don't have entity
  if (!ranch?.address || mapLoading) {
    return (
      <>
        <Skeleton variant="rounded" height={320} />
        <Skeleton
          className="my-4"
          variant="text"
          sx={{ fontSize: "2rem" }}
          width={100}
        />
      </>
    );
  }

  return (
    <div className="relative w-full">
      <div ref={mapContainer} className="map h-96 rounded-lg" />
      {ranch?.address && (
        <Text
          className="text-smooth-neutral-50 py-4"
          variant={TextVariant.BodySmall}
        >
          {ranch.address.city}, {ranch.address.state}
        </Text>
      )}
    </div>
  );
};

export default FeedLocationsMap;
