import React, { HTMLAttributes, ReactNode } from "react";
import classnames from "classnames";
import { TextVariant } from "../../utils/const";

// Map variants to Tailwind CSS classes
const variantStyles = {
  [TextVariant.DisplayLarge]:
    "font-sans text-display-lg leading-[64px] tracking-tighter",
  [TextVariant.DisplayMedium]:
    "font-sans text-display-md leading-[52px] tracking-normal",
  [TextVariant.DisplaySmall]:
    "font-sans text-display-sm leading-[44px] tracking-normal",
  [TextVariant.HeadlineLarge]:
    "font-sans text-headline-lg leading-[40px] tracking-normal",
  [TextVariant.HeadlineMedium]:
    "font-sans text-headline-md leading-[36px] tracking-normal",
  [TextVariant.HeadlineSmall]:
    "font-sans text-headline-sm leading-[32px] tracking-normal",
  [TextVariant.TitleLarge]:
    "font-sans text-title-lg leading-[28px] tracking-normal",
  [TextVariant.TitleMedium]:
    "font-sans text-title-md leading-[24px] tracking-wide",
  [TextVariant.TitleSmall]:
    "font-sans text-title-sm leading-[20px] tracking-wider",
  [TextVariant.LabelLarge]:
    "font-sans text-label-lg leading-[20px] tracking-widest",
  [TextVariant.LabelMedium]:
    "font-sans text-label-md leading-[16px] tracking-widest",
  [TextVariant.LabelSmall]:
    "font-sans text-label-sm leading-[16px] tracking-widest",
  [TextVariant.BodyLarge]:
    "font-sans text-body-lg leading-[24px] tracking-wider",
  [TextVariant.BodyMedium]:
    "font-sans text-body-md leading-[20px] tracking-wider",
  [TextVariant.BodySmall]:
    "font-sans text-body-sm leading-[16px] tracking-widest",
  [TextVariant.H1]: "font-sans text-headline-md leading-[36px] tracking-wide",
  [TextVariant.H2]: "font-sans text-headline-sm leading-[32px] tracking-wide",
  [TextVariant.H3]: "font-sans text-title-lg leading-[28px] tracking-wide",
  [TextVariant.H4]: "font-sans text-title-md leading-[24px] tracking-wide",
  [TextVariant.H5]: "font-sans text-title-sm leading-[20px] tracking-wide",
  [TextVariant.H6]: "font-sans text-title-sm leading-[18px] tracking-wide",
  [TextVariant.Label]: "font-sans text-label-md leading-[16px] tracking-widest",
  [TextVariant.Paragraph]:
    "font-sans text-body-md leading-[20px] tracking-wider",
};

// Helper to map variants to HTML elements
const variantElementMap: {
  [key in TextVariant]?: keyof JSX.IntrinsicElements;
} = {
  [TextVariant.H1]: "h1",
  [TextVariant.H2]: "h2",
  [TextVariant.H3]: "h3",
  [TextVariant.H4]: "h4",
  [TextVariant.H5]: "h5",
  [TextVariant.H6]: "h6",
  [TextVariant.Label]: "label",
  [TextVariant.Paragraph]: "p",
};

interface TextProps
  extends HTMLAttributes<HTMLHeadingElement | HTMLSpanElement> {
  variant: TextVariant;
  children: ReactNode;
  className?: string;
}

const Text = ({ variant, children, className }: TextProps) => {
  const Component = variantElementMap[variant] || "p";
  const textClasses = classnames(
    variantStyles[variant],
    className,
    "text-base-color",
    "block"
  );

  return <Component className={textClasses}>{children}</Component>;
};

export default Text;
