import { FeederModel } from "../models/feeder";
import { Axios } from "./core";

export const getFeedersByUserID = async (
  userID: number
): Promise<{ data: { feeders: FeederModel[] } }> =>
  await Axios.get(`/feeders/user/${userID}`);

export const getFeederByID = async (
  id: number
): Promise<{ data: { feeder: FeederModel } }> =>
  await Axios.get(`/feeders/${id}`);

export const createFeeder = async (
  payload: Partial<FeederModel>
): Promise<{ data: FeederModel }> =>
  await Axios.post("/feeders/create", payload);

export const editFeeder = async (
  id: number,
  payload: Partial<FeederModel>
): Promise<{ data: FeederModel }> =>
  await Axios.put(`/feeders/update/${id}`, payload);

export const deleteFeeder = async (id: number): Promise<void> =>
  await Axios.delete(`/feeders/delete/${id}`);
