import classNames from "classnames";
import React, { ReactNode } from "react";

const ActionList = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  const styles = classNames("space-y-2.5", className);
  return <ul className={styles}>{children}</ul>;
};

export default ActionList;
