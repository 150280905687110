import React, { useState, MouseEvent } from "react";
import TextInput, { TextInputProps } from "../TextInput";
import { IconButton, InputAdornment } from "@mui/material";

const PasswordInput = ({ id, name, placeholder, rules }: TextInputProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <TextInput
      id={id}
      name={name}
      label="Password"
      type={showPassword ? "text" : "password"}
      placeholder={placeholder}
      rules={rules}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {showPassword ? (
              <span className="material-symbols-rounded">visibility_off</span>
            ) : (
              <span className="material-symbols-rounded">visibility</span>
            )}
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

export default PasswordInput;
