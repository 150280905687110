import {
  action,
  computed,
  flow,
  makeObservable,
  observable,
  reaction,
} from "mobx";
import { IMission, MissionVitals } from "../../models/rover";
import BaseStore from "./BaseStore";
import RoverStore from "./RoverStore";
import { MissionResponse } from "../../api/mission";
import api from "../../api";
import { ISchedule } from "../../models/schedule";
import { formatTimeDaytime } from "../../utils/helpers";
import { ioClient } from "../../socket";
import RouteStore from "./RouteStore";

class MissionStore extends BaseStore<IMission> {
  roverStore: RoverStore;
  routeStore: RouteStore;
  missions = new Map<string, IMission>();
  activeMission: IMission | null = null;

  constructor(roverStore: RoverStore, routeStore: RouteStore) {
    super();
    this.roverStore = roverStore;
    this.routeStore = routeStore;
    makeObservable(this, {
      missions: observable,
      activeMission: observable,
      createMissionSchedule: flow,
      deleteMissionSchedule: flow,
      editMission: flow,
      runMission: flow,
      stopMission: flow,
      setMissions: action,
      fetchMissionsByRover: flow,
      getMissions: computed,
      getActiveMission: computed,
      getMissionByID: action,
      addMission: action,
      setActiveMission: action,
      updateMissionVitals: action,
      setupSocketListeners: action,
      removeMission: action,
    });

    // do this to make sure we always get the missions for a rover
    reaction(
      () => this.roverStore.getEntity,
      (rover) => {
        if (rover && rover.rover_id) {
          this.fetchMissionsByRover(rover.rover_id);
        } else {
          this.reset();
        }
      }
    );

    this.setupSocketListeners();
  }

  setupSocketListeners = () => {
    ioClient.onEvent<{ missionID: string; vitals: MissionVitals }>(
      "MISSION_VITALS",
      ({ missionID, vitals }) => {
        this.updateMissionVitals(missionID, { ...vitals });
      }
    );
  };

  updateMissionVitals(missionID: string, newVitals: MissionVitals) {
    const mission = this.getMissionByID(missionID);
    if (mission) {
      if (!this.getEntity) {
        this.setEntity(mission);
        this.addMission(mission);
      }

      const route = this.routeStore.getRouteByID(mission.route_id);

      // set the active mission and update vitals
      this.setActiveMission(mission);
      this.updateEntity({
        vitals: { ...newVitals, estimatedTime: Number(route?.estimated_time) },
      });
    }
  }

  *fetchMissionsByRover(roverId: string) {
    this.setFetchingEntity(true);
    this.setError(null);
    try {
      if (roverId) {
        const { data }: MissionResponse = yield api.fetchMissionsByRover(
          roverId
        );
        if (data && data.missions) {
          const missions = data.missions.map((m) => {
            const schedule = m.schedule as Partial<ISchedule>[];
            const times = schedule[0].times_of_day as unknown as string[];
            const mission = {
              ...m,
              schedule: {
                ...schedule[0],
                days_of_week: schedule[0].days_of_week,
                times_of_day: times.map((time: string) =>
                  formatTimeDaytime(time)
                ),
              },
            } as IMission;
            return mission;
          });
          this.setMissions(missions);
          // set default entity to first mission
          this.setEntity(missions[0]);
        }
      }
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setFetchingEntity(false);
    }
  }

  *runMission(missionId: string) {
    this.setError(null);
    try {
      const { data }: MissionResponse = yield api.runMission(missionId);
      if (data && data.mission) {
        // this.updateEntity({ data: { vitals: data.rover.data.vitals } });
      }
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  *stopMission(missionId: string) {
    this.setError(null);
    try {
      const { data }: MissionResponse = yield api.stopMission(missionId);
      if (data && data.mission) {
        // this.updateEntity({ data: { vitals: data.rover.data.vitals } });
      }
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  *createMissionSchedule(missionData: Partial<IMission>) {
    this.setLoading(true);
    this.setError(null);

    try {
      const { data }: MissionResponse = yield api.createMissionSchedule(
        missionData
      );
      if (data && data.mission) {
        // const schedule = data.mission.schedule as Partial<ISchedule>[];
        // const mission = {
        //   ...data.mission,
        //   schedule: {
        //     ...schedule[0],
        //     days_of_week: schedule[0].days_of_week,
        //     times_of_day: schedule[0].times_of_day,
        //   },
        // } as IMission;
        // console.log("built misison schedule", { ...mission });
        // console.log("built misison schedule 2", mission);
        this.addMission({ ...data.mission });
      }
    } catch (error) {
    } finally {
      this.setLoading(false);
    }
  }

  *deleteMissionSchedule(missionID: string) {
    this.setLoading(true);
    this.setError(null);

    try {
      yield api.deleteMissionSchedule(missionID);
      this.removeMission(missionID);
    } catch (error) {
      this.handleError(error);
    } finally {
      this.reset();
    }
  }

  *editMission(missionID: string, missionData: Partial<IMission>) {
    this.setLoading(true);
    this.setError(null);
    // TODO: add validation checks for request
    try {
      const { data }: MissionResponse = yield api.updateMission(
        missionID,
        missionData
      );
      if (data && data.mission) {
        const schedule = data.mission.schedule as Partial<ISchedule>[];
        const mission = {
          ...data.mission,
          schedule: {
            ...schedule[0],
            days_of_week: schedule[0].days_of_week,
            times_of_day: schedule[0].times_of_day,
          },
        } as IMission;
        this.addMission(mission);
      }
    } catch (error) {
    } finally {
      this.setLoading(false);
    }
  }

  removeMission(missionID: string) {
    this.missions.delete(missionID);
  }

  setActiveMission(mission: IMission | null): void {
    this.activeMission = mission;
  }

  addMission(mission: IMission) {
    this.missions.set(mission.mission_id, mission);
  }

  setMissions(missions: IMission[]) {
    this.missions.clear();
    missions.forEach((mission) => {
      this.addMission(mission);
    });
  }

  getMissionByID(missionID: string): IMission | undefined {
    const mission = this.missions.get(missionID);
    if (mission) {
      this.setEntity(mission);
    }
    return mission;
  }

  get getMissions(): IMission[] {
    return Array.from(this.missions.values()) || [];
  }

  get getActiveMission(): IMission | null {
    return this.activeMission;
  }

  reset(): void {
    this.setLoading(false);
    this.setError(null);
    this.setEntity(null);
  }
}

export default MissionStore;
