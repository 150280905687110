import React from "react";
import Text from "../Text";
import { TextVariant } from "../../utils";
import { Skeleton } from "@mui/material";
import { useStores } from "../../context";
import { observer } from "mobx-react";

const MapLocation = observer(() => {
  const { ranchStore } = useStores();
  const ranch = ranchStore.getEntity;

  // show loading if we don't have entity
  if (!ranch?.address || ranchStore.isLoading) {
    return (
      <Skeleton
        className="my-4"
        variant="text"
        sx={{ fontSize: "2rem" }}
        width={100}
      />
    );
  }

  return (
    <Text
      className="text-smooth-neutral-50 py-4"
      variant={TextVariant.BodySmall}
    >
      {ranch.address.city}, {ranch.address.state}
    </Text>
  );
});

export default MapLocation;
