import React, { useRef } from "react";
import {
  useFormContext,
  Controller,
  UseControllerProps,
} from "react-hook-form";
import {
  DesktopDatePicker as BaseDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { createTheme } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

interface DatePickerProps extends UseControllerProps {
  name: string;
  label: string;
  className?: string;
}

const DatePicker = ({
  name,
  label,
  className,
  rules,
  ...controllerProps
}: DatePickerProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const inputRef = useRef<HTMLInputElement>(null);
  const error = errors[name];
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        {...controllerProps}
        render={({ field: { onChange, value, ...restField } }) => (
          <>
            <BaseDatePicker
              label={label}
              slotProps={{ textField: { variant: "outlined" } }}
              inputRef={inputRef}
              value={value ? dayjs(value) : null}
              onChange={(newValue) => {
                if (newValue && newValue.isValid()) {
                  onChange(newValue.toISOString());
                } else {
                  // Handle invalid date, e.g., set to null or keep the old value
                  onChange(null);
                }
              }}
              {...restField}
            />
            {error?.message && (
              <span className="text-xs text-red-600">
                {error.message?.toString()}
              </span>
            )}
          </>
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
