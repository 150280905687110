import { ReactNode } from "react";
import ActionList from "./ActionList";

const ActionMenu = ({ children }: { children: ReactNode }) => (
  <>
    {/* List of actions */}
    <ActionList>{children}</ActionList>
  </>
);

export default ActionMenu;
