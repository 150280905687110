import { Step, StepContent, StepLabel, Stepper } from "@mui/material";
import React, { ReactNode, useState } from "react";
import Text from "../Text";
import { TextVariant } from "../../utils/const";
import { OutlineButton, PrimaryButton } from "../Button";
import { useFormSubmitContext } from "./context";

type StepItem = {
  id: string;
  label: string;
  content: ReactNode;
  triggerSubmit?: () => Promise<boolean>;
};

export type Steps = StepItem[];

interface VerticalStepperProps {
  steps: Steps;
  onCompleteHandler?: () => Promise<void>;
}

export const VerticalStepper = ({
  steps,
  onCompleteHandler,
}: VerticalStepperProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const { triggerCurrentSubmit } = useFormSubmitContext();

  const handleNext = async (formId: string) => {
    const isFormValid = await triggerCurrentSubmit(formId);
    if (isFormValid) {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      {steps.map((step, idx) => {
        const lastStep = idx === steps.length - 1;
        return (
          <Step key={step.label}>
            <StepLabel
              optional={
                lastStep ? (
                  <Text variant={TextVariant?.BodySmall}>Last step</Text>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent TransitionProps={{ unmountOnExit: false }}>
              <div>{step.content}</div>
              <div className="mt-6 grid grid-cols-2 gap-2 max-w-full">
                <PrimaryButton
                  type="button"
                  onClickHandler={async () => {
                    handleNext(step.id).then(async () => {
                      if (lastStep && onCompleteHandler) {
                        await onCompleteHandler();
                      }
                    });
                  }}
                >
                  {lastStep ? "Complete Setup" : "Continue"}
                </PrimaryButton>
                {idx > 0 && (
                  <OutlineButton onClickHandler={handleBack}>
                    Back
                  </OutlineButton>
                )}
              </div>
            </StepContent>
          </Step>
        );
      })}
    </Stepper>
  );
};
