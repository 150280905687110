import React, { useCallback, useEffect, useState } from "react";
import { useMap } from "../../hooks/useMap";
import MapboxDraw, {
  DrawDeleteEvent,
  DrawUpdateEvent,
  DrawCreateEvent,
} from "@mapbox/mapbox-gl-draw";
import { useStores } from "../../context";
import { observer } from "mobx-react";
import { Skeleton } from "@mui/material";
import { MapSettings } from "../../models/map";
import MapMetadata from "../MapBox/MapMetadata";

const MapView = observer(
  ({ settings }: { settings?: Partial<MapSettings> }) => {
    const { ranchStore } = useStores();
    const ranch = ranchStore.getEntity;
    const [features, setFeatures] = useState<{
      [key: string]: any;
    }>({});

    const { map, mapContainer } = useMap({
      ...settings,
      center: ranch?.address ? { ...ranch?.address } : settings?.center,
    });

    const onUpdate = useCallback((e: DrawCreateEvent | DrawUpdateEvent) => {
      setFeatures((prev) => {
        const newFeatures = { ...prev };
        for (const f of e.features) {
          if (typeof f.id !== "undefined") {
            newFeatures[f.id] = f;
          }
        }
        return newFeatures;
      });
    }, []);

    const onDelete = useCallback((e: DrawDeleteEvent) => {
      setFeatures((prev) => {
        const newFeatures = { ...prev };
        for (const f of e.features) {
          if (typeof f.id !== "undefined") {
            delete newFeatures[f.id];
          }
        }
        return newFeatures;
      });
    }, []);

    useEffect(() => {
      const draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true,
        },
        defaultMode: "draw_polygon",
      });

      if (map) {
        map.addControl(draw);
        map.on("draw.create", onUpdate);
        map.on("draw.update", onUpdate);
        map.on("draw.delete", onDelete);
      }

      // Cleanup function
      return () => {
        map?.removeControl(draw);
      };
    }, [map, onDelete, onUpdate]);

    if (!ranch || ranchStore.fetchingEntity) {
      return (
        <>
          <Skeleton variant="rounded" height={288} />
          <Skeleton
            className="my-4"
            variant="text"
            sx={{ fontSize: "2rem" }}
            width={100}
          />
        </>
      );
    }

    return (
      <div className="relative w-full">
        <div ref={mapContainer} className="map h-96 md:h-[500px] rounded-lg" />
        <MapMetadata />
      </div>
    );
  }
);

export default MapView;
