import { IUser } from "../models/user";
import { Axios } from "./core";

const BASE_PATH = "/users";

export interface UserResponse {
  data: { user?: IUser; resp?: any };
}

export const createUser = async (
  payload: Partial<IUser>
): Promise<UserResponse> => await Axios.post(BASE_PATH, payload);

export const updateUser = async (
  userID: string,
  payload: Partial<IUser>
): Promise<UserResponse> =>
  await Axios.patch(`${BASE_PATH}/${userID}`, payload);

export const completeUserSetup = async (
  userID: string
): Promise<UserResponse> =>
  await Axios.patch(`${BASE_PATH}/${userID}/complete-setup`);

export const deleteUser = async (userID: string): Promise<void> =>
  await Axios.delete(`${BASE_PATH}/${userID}`);

export const getUserByID = async (userID: string): Promise<UserResponse> =>
  await Axios.get(`${BASE_PATH}/${userID}`);

export const getUserByEmail = async (email: string): Promise<UserResponse> =>
  await Axios.get(`${BASE_PATH}/${email}`);
