import React from "react";
import Button, { ButtonProps } from "./Button";
import classNames from "classnames";

const PrimaryButton = ({
  children,
  onClickHandler,
  className,
  type = "button",
}: ButtonProps) => {
  const buttonClasses = classNames(
    "btn btn-primary bg-smooth-primary border border-smooth-primary text-smooth-white px-6 py-2.5 active:bg-smooth-primary-60 hover:bg-smooth-primary-60",
    className
  );
  return (
    <Button
      className={buttonClasses}
      onClickHandler={onClickHandler}
      type={type}
    >
      {children}
    </Button>
  );
};

export default PrimaryButton;
