import React from "react";
import Button, { ButtonProps } from "./Button";
import classNames from "classnames";

const DangerButton = ({ children, onClickHandler, className }: ButtonProps) => {
  const styles =
    "btn-ghost font-medium text-smooth-error-40 w-full bg-smooth-error-90 border-smooth-error-90 active:bg-smooth-error-95 focus:bg-smooth-error-95 hover:bg-smooth-error-95";
  return (
    <Button
      className={classNames(styles, className)}
      onClickHandler={onClickHandler}
    >
      {children}
    </Button>
  );
};

export default DangerButton;
