import { useEffect } from "react";
import {
  DeepPartial,
  FieldValues,
  useForm,
  UseFormReturn,
} from "react-hook-form";
import { autorun, toJS } from "mobx";

// Interface defining a store that holds an entity of generic type T.
interface StoreWithEntity<T> {
  getEntity: DeepPartial<T> | null;
}

// This custom hook is designed to integrate react-hook-form with a MobX store.
// It uses generics to ensure type safety and flexibility,
// allowing it to work with any entity type that extends FieldValues.
export const useMobxForm = <T extends FieldValues>({
  store,
  defaultValues,
}: {
  store?: StoreWithEntity<T>;
  defaultValues?: DeepPartial<T>;
}): UseFormReturn<T> => {
  // Initialize the form using react-hook-form's useForm hook.
  // The form's default values are set based on the current entity in the store.
  // If the entity exists, its observable properties are converted to a plain
  // object using toJS for compatibility with react-hook-form.
  // DeepPartial<T> is used to allow partial objects, acknowledging that not all
  // properties may be provided initially.
  const methods = useForm<T>({
    defaultValues: store?.getEntity ? toJS(store.getEntity) : defaultValues,
    mode: "onChange",
  });

  // This effect subscribes to changes in the store's entity using MobX's autorun.
  // Whenever the entity changes, the form is reset with the new entity values.
  // This ensures the form always reflects the current state of the entity in the store.
  useEffect(() => {
    const disposeAutorun = autorun(() => {
      if (store?.getEntity) {
        // Convert the entity to a plain object and reset the form with its values.
        // This action ensures that the form is updated reactively whenever the store's entity changes.
        const entity = toJS(store.getEntity);
        methods.reset(entity);
      }
    });

    // Cleanup function to dispose of the autorun subscription when the component unmounts.
    // This prevents memory leaks and ensures that the autorun is only active while the component is mounted.
    return () => disposeAutorun();
  }, [store, methods]);

  return methods;
};
