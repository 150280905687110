import React, { FC } from "react";
import { useFormContext, Controller } from "react-hook-form";
import classNames from "classnames";

interface RadioButtonStackedProps {
  name: string;
  label: string;
  value: string;
  className?: string;
}

const RadioButtonStacked: FC<RadioButtonStackedProps> = ({
  name,
  label,
  value,
  className,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const radioClassNames = classNames(
          "relative join-item py-1.5 px-2.5 border text-center w-14",
          className,
          {
            "bg-smooth-secondary-90 border-smooth-primary":
              field.value === value,
            "border-smooth-neutral-variant-60 bg-smooth-white":
              field.value !== value,
          }
        );

        return (
          <>
            <label className={radioClassNames}>
              <span className="text-sm">{label}</span>
              <input
                type="radio"
                className="opacity-0 absolute w-full"
                {...field}
                value={value}
                checked={field.value === value}
                onChange={() => field.onChange(value)}
              />
            </label>
          </>
        );
      }}
    />
  );
};

export default RadioButtonStacked;
