import { observable, action, flow } from "mobx";
import { IHerd } from "../../models/cattle";
import api from "../../api";

/**
 * HerdStore manages the state and operations related to herds in the application.
 * It handles the retrieval, updating, and tracking of herd information.
 */
class HerdStore {
  @observable herd: IHerd[] = [];
  @observable isLoading = false;
  @observable error: string | null = null;

  constructor() {
    // Removed makeObservable as we're using decorators
  }

  /**
   * Sets the herd data.
   * @param {IHerd[]} herd - Array of herd data to set.
   */
  @action
  private setHerd(herd: IHerd[]): void {
    this.herd = herd;
  }

  /**
   * Sets the loading state.
   * @param {boolean} isLoading - The loading state to set.
   */
  @action
  private setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  /**
   * Sets the error message.
   * @param {string | null} error - The error message to set.
   */
  @action
  private setError(error: string | null): void {
    this.error = error;
  }

  /**
   * Handles errors by setting the appropriate error message.
   * @param {any} error - The error to handle.
   */
  @action
  private handleError(error: any): void {
    const message =
      error instanceof Error ? error.message : "An unexpected error occurred";
    this.setError(message);
  }

  /**
   * Resets the store to its initial state.
   */
  @action
  reset(): void {
    this.setHerd([]);
    this.setIsLoading(false);
    this.setError(null);
  }

  /**
   * Fetches herd data from the API and updates the store.
   */
  @flow
  *fetchHerd() {
    this.setIsLoading(true);
    try {
      // const herd = yield api.getHerd(); // Replace with actual API call
      // this.setHerd(herd);
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setIsLoading(false);
    }
  }
}

export default HerdStore;
