import React from "react";
import { WeatherVitals } from "../Vitals";
import MapLocation from "./MapLocation";

const MapMetadata = () => (
  <div className="flex justify-between">
    {/* Shows the ranch location/map center */}
    <MapLocation />

    {/* Latest weather vitals */}
    <WeatherVitals />
  </div>
);

export default MapMetadata;
