import React from "react";
import { Link } from "react-router-dom";
import Avatar from "../Avatar";
import { useNavigate } from "../../hooks/useNavigate";
import { useStores } from "../../context";
import { observer } from "mobx-react";
import { PrimaryLogoFull, PrimaryLogo } from "../../Icons";
import { getFullName } from "../../models/user";
import Loading from "../Loading";

type AppBarProps = {
  onMenuClick: () => void;
};

const AppBar = observer(({ onMenuClick }: AppBarProps) => {
  const { authStore } = useStores();
  const user = authStore.getEntity;
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      authStore.logout();
      navigate("/login");
    } catch (error) {
      console.error({ error });
    }
  };

  if (authStore.getIsAuthenticated && (!user || authStore.isLoading)) {
    return <Loading />;
  }

  const currentDate = new Date(user?.created_at ?? "").toLocaleDateString(
    "en-GB",
    {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }
  );

  return (
    <nav className="navbar bg-smooth-neutral-98 h-20">
      <div className="navbar-start">
        {authStore.getIsAuthenticated && (
          <div className="flex-none">
            <button
              className="btn btn-square bg-smooth-neutral-98 border-none active:bg-smooth-base-50 focus:bg-smooth-base-50"
              onClick={onMenuClick}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75H12a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        )}
      </div>
      <div className="navbar-center max-w-full">
        <Link className="normal-case text-xl text-smooth-secondary-50" to="/">
          {(authStore.getIsAuthenticated && <PrimaryLogo />) || (
            <PrimaryLogoFull />
          )}
        </Link>
      </div>
      <div className="navbar-end">
        {authStore.getIsAuthenticated && (
          <>
            <div className="dropdown dropdown-end">
              <label tabIndex={0} className="btn btn-ghost btn-circle">
                <div className="indicator">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                    />
                  </svg>
                  <span className="badge badge-sm badge-primary bg-smooth-primary border-smooth-primary indicator-item">
                    1
                  </span>
                </div>
              </label>
              <ul
                tabIndex={0}
                className="dropdown-content z-[100] mt-3 p-6 w-80 shadow bg-base-100 rounded-md"
              >
                <li className="menu-title text-smooth-base-700 p-0">
                  Notifications
                  <span className="divider mt-1 mb-2" />
                </li>
                <li>
                  <span className="flex items-center text-smooth-primary-60 font-bold text-sm mr-2">
                    Welcome!
                  </span>
                  <p className="text-sm">Welcome to RanchSync by SmoothAG.</p>
                  <p className="text-xs mt-1 text-smooth-base-600">
                    {currentDate}
                  </p>
                  {/* <p className="text-sm">
                    Rover has been stopped for: &nbsp;
                    <span className="text-error text-bold">10 minutes</span>.
                    <br />
                    This indicates something is wrong, please go check on the
                    rover.
                  </p>
                  <p className="text-xs mt-1 text-smooth-base-600">
                    23 July, 2023
                  </p> */}
                </li>
              </ul>
            </div>

            <div className="dropdown dropdown-end ml-3">
              <label tabIndex={0} className="cursor-pointer">
                <Avatar name={getFullName(user)} />
              </label>
              <ul
                tabIndex={0}
                className="dropdown-content z-[100] menu mt-4 p-2 shadow bg-base-100 rounded-md w-60"
              >
                <li>
                  <Link
                    to="/user-account"
                    className="active:!bg-smooth-secondary-60 active:!text-smooth-white hover:!bg-smooth-secondary-60 hover:!text-smooth-white"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                      />
                    </svg>
                    My Account
                  </Link>
                </li>
                <li>
                  <Link
                    to="/settings"
                    className="active:!bg-smooth-secondary-60 active:!text-smooth-white hover:!bg-smooth-secondary-60 hover:!text-smooth-white"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                    Settings
                  </Link>
                </li>
                <span className="divider my-1 px-2 hover:bg-base-100 hover:cursor-default active:bg-base-100 active:cursor-default" />
                <li>
                  <button
                    className="btn btn-ghost py-2 text-smooth-error font-normal text-left justify-start normal-case min-h-0 h-full w-full"
                    onClick={handleLogout}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
                      />
                    </svg>
                    Logout
                  </button>
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
    </nav>
  );
});

export default AppBar;
