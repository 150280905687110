import { ElementType, lazy } from "react";

export interface RouteConfig {
  name: string;
  path: string;
  element: ElementType;
  private?: boolean;
  children?: RouteConfig[];
}

export const routes: RouteConfig[] = [
  {
    name: "Welcome",
    path: "/welcome",
    element: lazy(() => import("./views/Dashboard/Welcome")),
    private: true,
  },
  {
    name: "Dashboard",
    path: "/",
    element: lazy(() => import("./views/Dashboard")),
    private: true,
  },
  {
    name: "Rover Details",
    path: "/rovers/:roverID",
    element: lazy(() => import("./views/Rover/Details")),
    private: true,
  },
  {
    name: "Style Guide",
    path: "/styleguide",
    element: lazy(() => import("./views/StyleGuide")),
    private: true,
  },
  {
    name: "Login",
    path: "/login",
    element: lazy(() => import("./views/Login")),
    private: false,
  },
  {
    name: "Register User",
    path: "/register-user",
    element: lazy(() => import("./views/Register")),
    private: false,
  },
  {
    name: "User Account",
    path: "/user-account",
    element: lazy(() => import("./views/UserAccount")),
    private: true,
  },
  {
    name: "Cattle Overview",
    path: "/cattle",
    element: lazy(() => import("./views/Cattle/Dashboard")),
    private: true,
  },
  {
    name: "Cattle Details",
    path: "/cattle/:cattleId",
    element: lazy(() => import("./views/Cattle/Details")),
    private: true,
  },
];
